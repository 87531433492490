import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, } from "react-router-dom"
import { Card, CardBody, Row, Col, Alert, Button, Label, Input, UncontrolledTooltip } from "reactstrap"
import {
  getSharedTableOptions,
  showError,
  showSuccess,
  showWarning,
  getSharedPaginationOptions,
  getMiles
} from "helpers/utilHelper"
import { addOrderNotaryBid, doOrderNotaryDtCleanup, getOrderNotaryDt } from "store/actions";
import { route, routes } from "helpers/routeHelper";
import Confirmation from "components/Shared/Confirmation";
import Filters from './NotariesFilters';
import { NotFoundException, ORDER_SCHEDULER_MISSING, ServerErrorException, UNABLE_SEND_SCHEDULER_ASSIGNED_TO_ORDER_NOTIF } from "helpers/errorHelper";
import assignNotaryIcon from "assets/images/assign-notary-icon.svg";
import alreadyAssignNotaryIcon from "assets/images/already-assign-notary-icon.svg";
import { omit } from "lodash";
import GoogleMapsLocations from "components/Shared/GoogleMapsLocations";
import classnames from "classnames";

const DataTableNotaries = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { order } = useSelector(state => state.Order.Single);
  const { added, addError } = useSelector(state => state.Order.NotaryBid);
  const filters = useSelector(state => state.Order.NotaryDtFilters);

  const { notaries: rows, notariesError: rowsError, totalCount, listParams, isLoadInProgress } = useSelector(state => state.Order.NotaryDt);
  // locations to be displayed as pins on the map
  const locations = rows.map(row => ({ notaryName: row.userFullName, notaryRating: row.rating, distance: row.distance, latitude: row.shippingLatitude, longitude: row.shippingLongitude }))
  const [notaryToAdd, setNotaryToAdd] = useState(null);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isMapVisible, setIsMapVisible] = useState(false);

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    sizePerPageList: [
      { text: '1', value: 1 },
      { text: '5', value: 5 },
      { text: '50', value: 50 },
    ],
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    defaultSorted: [{
      dataField: listParams.sortBy,
      order: listParams.sortDir,
    }],
  });

  /********** EFFECTS **********/

  // runs whenever "totalCount" changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let's update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  // runs whenever "filters" changes
  // which happens after "apply-filters" or "clear-filters"
  // but also on component mount
  useEffect(() => {
    // "_set" is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty("_set") && !filters._set) {
      return;
    }
    // refresh the list data based on the new filters
    refreshNotaries();
  }, [filters]);

  useEffect(() => {
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderNotaryDtCleanup());
    }
  }, [])

  useEffect(() => {
    if (added === true) {
      showSuccess("Notary has been added");
      refreshNotaries();
    } else if (added === false) {
      if (addError instanceof ServerErrorException) {
        if (addError.code == UNABLE_SEND_SCHEDULER_ASSIGNED_TO_ORDER_NOTIF) {
          // notary has been added but the notifications could not be sent (at least some of them)
          showWarning("Unable to send notifications");
          return;
        }
      }
      if (addError instanceof NotFoundException) {
        if (addError.code == ORDER_SCHEDULER_MISSING) {
          // notary can't be added, beacause there is no scheduler assigned
          showError("No scheduler assigned to this order");
          return;
        }
      }
      showError("Unable to add notary");
      setNotaryToAdd(null);
    }
  }, [added]);

  /********** EVENT HANDLERS **********/

  // runs whenever table params change (sorting, pagination, search, etc)
  const handleTableChange = (_type, newState) => {
    // "_set" is a special flag we use to know if the default filter values have been initialized (ex. from url)
    // we want to ingore this event before the filters are initialized
    // therefore avoiding a duplicate call to the backend
    if (filters.hasOwnProperty("_set") && !filters._set) {
      return;
    }
    // refresh the list data based on the new table params
    dispatch(getOrderNotaryDt(
      order.id,
      {
        ...listParams,
        sortBy: newState.sortField,
        sortDir: newState.sortOrder,
        pageSize: newState.sizePerPage,
        page: newState.page,
        search: newState.searchText,
      }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  const confirmAddNotary = user => {
    setNotaryToAdd(user);
    setIsConfirmationVisible(true);
  }

  const addNotary = () => {
    setIsConfirmationVisible(false);
    dispatch(addOrderNotaryBid(order.id, notaryToAdd.id));
  }

  const refreshNotaries = () => {
    dispatch(getOrderNotaryDt(
      order.id,
      {
        ...listParams,
        // "_set" is a special flag we use to know if the default filter values have been initialized
        // we do not want that passed to back-end
        filters: omit(filters, "_set"),
        // reset the page number when filtering
        // otherwise the current page number might be higher than the total number of pages after the filtering
        page: 1,
      }));
    // update the pagination with the new page number
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }

  return <React.Fragment>
    <Card>
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columns(confirmAddNotary, order)}
              data={rows}
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Filters />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          headerWrapperClasses="thead-light hide-expand-all"
                          noDataIndication={!rowsError && !isLoadInProgress && "No notaries found"}
                          onTableChange={handleTableChange}
                          defaultSorted={paginationProps.defaultSorted}
                          expandRow={expandRow}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load notaries
                      </Alert>}
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
    {rows.length > 0 && <Row className="align-items-center mb-4">
      <Col xs="auto">
        <Label className="col-form-label font-size-15 fw-bold me-2">Display notaries on the map</Label>
      </Col>
      <Col xs="auto">
        <div className="form-check form-switch form-switch-lg">
          <Input type="checkbox" className="form-check-input" id="isMapVisibleSwitch" onChange={() => setIsMapVisible(!isMapVisible)} />
          <Label className="form-check-label" htmlFor="isMapVisibleSwitch" />
        </div>
      </Col>
    </Row>}
    {isMapVisible && <GoogleMapsLocations locations={locations} center={locations[0]} />}
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Add"
      reverseButtons={false}
      onConfirm={() => addNotary()}
      onCancel={() => {
        setNotaryToAdd(null);
        setIsConfirmationVisible(false);
      }}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to add notary &quot;{notaryToAdd.userFullName}&quot; to the bidding queue?</span>
    </Confirmation>}
  </React.Fragment>
}

const columns = (confirmAddNotary, order) => [{
  // this column is not used but we need it for the default sorting to work
  // if dataField does not match a defined column, it will be ignored
  dataField: "id",
  sort: true,
  hidden: true,
}, {
  dataField: "userFullName",
  text: "Name",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <Link to={route(routes.view_notary, row.id)}>{cellContent}</Link>
}, {
  dataField: "initialRating",
  text: "Mav1 Rating",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => (cellContent ? <>{cellContent} <i className="mdi mdi-star-outline mb-2 rating-star text-warning" /></> : "--")
}, {
  dataField: "rating",
  text: "Rating",
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => (cellContent ? <>{cellContent} <i className="mdi mdi-star-outline mb-2 rating-star text-warning" /></> : "--")
}, {
  dataField: "signingCount",
  text: "Number of signings",
  sort: true,
}, {
  dataField: "typeName",
  text: "Type",
  sort: true,
}, {
  dataField: "countiesCovered",
  text: "Coverage Area",
  sort: false,
  classes: "additional-details-cell",
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent || "--"
}, {
  dataField: "shippingState",
  text: "State",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent || "--"
}, {
  dataField: "distance",
  text: "Distance",
  sort: true,
  formatter: (cellContent, row) => cellContent != null ? `${getMiles(row.distance)} miles` : "--"
}, {
  dataField: "scheduleAlert",
  text: "Additional Details",
  sort: false,
  classes: "additional-details-cell",
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent || "--"
}, {
  dataField: "dummy2",
  text: "Add Notary",
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    if (!order.schedulerId) {
      return <>
        <span id="no-scheduler">
          <img alt="noSchedulerAssigned" className="assign-notary-disabled" src={alreadyAssignNotaryIcon} />
        </span>
        <UncontrolledTooltip placement="top" target="no-scheduler">
          No scheduler assigned to this order
        </UncontrolledTooltip>
      </>
    }
    if (row.notaryBidStatus === null) {
      return <Button type="button" color="link" onClick={() => confirmAddNotary(row)}>
        <span id="not-added">
          <img alt="assign-notary" src={assignNotaryIcon} />
        </span>
        <UncontrolledTooltip placement="top" target="not-added">
          Add to bidding process
        </UncontrolledTooltip>
      </Button>;
    }
    return <>
      <span id="already-added">
        <img alt="alreadyAssignNotaryIcon" className="assign-notary-disabled" src={alreadyAssignNotaryIcon} />
      </span>
      <UncontrolledTooltip placement="top" target="already-added">
        Already added to the bidding process
      </UncontrolledTooltip>
    </>
  },
},];

const expandRow = {
  showExpandColumn: true,
  expandColumnPosition: "right",
  expandByColumnOnly: true,
  parentClassName: "expanded-dt-row",
  // eslint-disable-next-line react/display-name, react/prop-types
  expandColumnRenderer: ({ expanded }) => (
    <button
      className={classnames(
        "accordion-button",
        "w-auto",
        "fw-medium",
        { collapsed: !expanded }
      )}
      type="button"
    />
  ),
  // eslint-disable-next-line react/display-name
  renderer: (row) => (
    <></>
  ),
};

export default DataTableNotaries;
