import React from 'react';
import { useAuth } from './auth';
import { getDealerAppUrl, getNotaryAppUrl, toUserModel } from 'helpers/utilHelper';
import Message from 'model/message';

export const perms = {
  view_users: 'view_users',
  create_users: 'create_users',
  create_user: 'create_user',
  edit_users: 'edit_users',
  edit_user: 'edit_user',
  change_user_role: 'change_user_role',
  delete_users: 'delete_users',
  delete_user: 'delete_user',
  impersonate_users: 'impersonate_users',
  view_dealer_groups: 'view_dealer_groups',
  create_dealer_groups: 'create_dealer_groups',
  edit_dealer_groups: 'edit_dealer_groups',
  edit_can_pay_with_check: 'edit_can_pay_with_check',
  edit_dealer_special_instructions: 'edit_dealer_special_instructions',
  delete_dealer_groups: 'delete_dealer_groups',
  view_dealer_regions: 'view_dealer_regions',
  create_dealer_regions: 'create_dealer_regions',
  edit_dealer_regions: 'edit_dealer_regions',
  delete_dealer_regions: 'delete_dealer_regions',
  view_dealer_stores: 'view_dealer_stores',
  view_user_managed_stores: 'view_user_managed_stores',
  view_dealer_store_account_manager: 'view_dealer_store_account_manager',
  assign_account_managers: 'assign_account_managers',
  create_dealer_stores: 'create_dealer_stores',
  edit_dealer_stores: 'edit_dealer_stores',
  edit_dealer_stores_QB: 'edit_dealer_stores_QB',
  create_dealer_store_users: 'create_dealer_store_users',
  view_orders: 'view_orders',
  edit_orders: 'edit_orders',
  cancel_order: 'cancel_order',
  delete_order: 'delete_order',
  edit_order_shipping: 'edit_order_shipping',
  add_order_services: 'add_order_services',
  toggle_rush_service_for_orders: 'toggle_rush_service_for_orders',
  view_quickbooks: 'view_quickbooks',
  mark_orders_as_ready_for_billing: 'mark_orders_as_ready_for_billing',
  retry_order_payments: 'retry_order_payments',
  edit_order_fees: 'edit_order_fees',
  view_payment_plans: 'view_payment_plans',
  create_payment_plans: 'create_payment_plans',
  edit_payment_plans: 'edit_payment_plans',
  view_payment_plans_filter: 'view_payment_plans_filter',
  delete_payment_plans: 'delete_payment_plans',
  view_statements: 'view_statements',
  create_dealer_store_signers: 'create_dealer_store_signers',
  extend_free_trials: 'extend_free_trials',
  view_support_cases: 'view_support_cases',
  view_own_scheduled_support_cases: 'view_own_scheduled_support_cases',
  assign_support_cases: 'assign_support_cases',
  edit_support_cases: 'edit_support_cases',
  create_support_case_comments: 'create_support_case_comments',
  delete_support_case_comments: 'delete_support_case_comments',
  view_notaries: 'view_notaries',
  view_order_notary_status: 'view_order_notary_status',
  view_order_internal_status: 'view_order_internal_status',
  view_order_accounting: 'view_order_accounting',
  view_order_sealed_doc: 'view_order_sealed_doc',
  view_order_vid: 'view_order_vid',
  view_order_messages: 'view_order_messages',
  view_order_channel: 'view_order_channel',
  export_order_seal: 'export_order_seal',
  edit_order_accounting: 'edit_order_accounting',
  assign_schedulers: 'assign_schedulers',
  assign_notaries: 'assign_notaries',
  review_notaries: 'review_notaries',
  create_customer_calls: 'create_customer_calls',
  create_notary_calls: 'create_notary_calls',
  view_messages: 'view_messages',
  view_leaderboard_list: 'view_leaderboard_list',
  delete_notaries: 'delete_notaries',
  delete_notary_credentials: 'delete_notary_credentials',
  check_user_email_alert: 'check_user_email_alert',
  view_notary_bidding_logs: 'view_notary_bidding_logs',
  edit_notaries: 'edit_notaries',
  edit_notaries_QB: 'edit_notaries_QB',
  send_statements_QB: 'send_statements_QB',
  view_message_channel: 'view_message_channel',
  impersonate_user: 'impersonate_user',
  view_additional_fees: 'view_additional_fees',
  edit_additional_fees: 'edit_additional_fees',
  view_scheduler_performance_reports: 'view_scheduler_performance_reports',
  edit_notary_rating_comments: 'edit_notary_rating_comments',
  edit_notary_rating: 'edit_notary_rating',
  view_payments: 'view_payments',
  view_biddings: 'view_biddings',
  view_group_usage_reports: 'view_group_usage_reports',
  view_cron_jobs: 'view_cron_jobs',
  upload_supporting_documents: 'upload_supporting_documents',
  upload_preview_documents: 'upload_preview_documents',
  edit_uses_coupa: 'edit_uses_coupa',
  view_error_codes: 'view_error_codes',
  view_system_processes: 'view_system_processes',
  skip_vid: 'skip_vid',
  verify_users: 'verify_users',
  unlock_account: 'unlock_account',
  refresh_order_statuses: 'refresh_order_statuses',
  resubmit_vid_requests: 'resubmit_vid_requests',
  delete_vid_requests: 'delete_vid_requests',
  mark_orders_as_rural: 'mark_orders_as_rural',
  view_pending_review_orders: 'view_pending_review_orders',
  view_similar_orders: 'view_similar_orders',
  edit_order_accounting_status: 'edit_order_accounting_status',
  remove_notary: 'remove_notary',
  edit_store_payment_plan: 'edit_store_payment_plan',
  edit_coupa_information: 'edit_coupa_information',
  view_dealer_sales_reports: 'view_dealer_sales_reports',
  view_own_dcs_support_cases: 'view_own_dcs_support_cases',
  view_new_dealers_reports: 'view_new_dealers_reports',
  add_order_accounting_review: 'add_order_accounting_review',
  resolve_order_accounting_review: 'resolve_order_accounting_review',
  add_order_duplicate_review: 'add_order_duplicate_review',
  resolve_order_duplicate_review: 'resolve_order_duplicate_review',
  add_order_under_review: 'add_order_under_review',
  resolve_order_under_review: 'resolve_order_under_review',
  add_order_on_hold_review: 'add_order_on_hold_review',
  resolve_order_on_hold_review: 'resolve_order_on_hold_review',
  edit_appointment_date: 'edit_appointment_date',
  view_route_one_logs: 'view_route_one_logs',
};

const AccessContext = React.createContext();

const AccessProvider = props => {

  const { user: authUser } = useAuth();

  const iAmMasterAdmin = () => authUser && authUser.isMasterAdmin();
  const iAmAdmin = () => authUser && authUser.isAdmin();
  const iAmScheduler = () => authUser && authUser.isScheduler();
  const iAmDsc = () => authUser && authUser.isDsc();
  const iAmAccountManager = () => authUser && authUser.isAccountManager();
  const iAmLeadOps = () => authUser && authUser.isLeadOps();
  const iAmDealerGroupManager = () => authUser && authUser.isDealerGroupManager();
  const iAmDealerRegionalManager = () => authUser && authUser.isDealerRegionalManager();
  const iAmDealerStoreManager = () => authUser && authUser.isDealerStoreManager();
  const iAmDealerFinanceManager = () => authUser && authUser.isDealerFinanceManager();
  const iAmDealerSalesManager = () => authUser && authUser.isDealerSalesManager();
  const iAmDealerUpperManager = () => authUser && authUser.isDealerUpperManager();
  const iAmDealerLowerManager = () => authUser && authUser.isDealerLowerManager();
  const iAmDealerManager = () => authUser && authUser.isDealerManager();
  const iAmDealerStoreUser = () => authUser && authUser.isDealerStoreUser();
  const iAmDealerStoreLimitedUser = () => authUser && authUser.isDealerStoreLimitedUser();

  const iAmAdminType = () => authUser && authUser.isAdminType();
  const iAmDealerType = () => authUser && authUser.isDealerType();
  const iAmNotaryType = () => authUser && authUser.isNotaryType();

  const iAmGranted = (attribute, subject) => {
    switch (attribute) {
      case perms.view_users:
      case perms.impersonate_users:
      case perms.view_dealer_groups:
      case perms.view_dealer_regions:
      case perms.view_orders:
      case perms.view_order_messages:
      case perms.view_order_vid:
      case perms.view_order_sealed_doc:
      case perms.edit_order_fees:
      case perms.view_additional_fees:
      case perms.view_payment_plans:
      case perms.view_support_cases:
      case perms.view_notaries:
      case perms.view_order_channel:
        return true;
      // users
      case perms.create_users:
      case perms.edit_users:
        return iAmMasterAdmin() || iAmAdmin() || iAmAccountManager() || iAmLeadOps() || iAmScheduler() || iAmDealerType();
      case perms.verify_users:
        return iAmMasterAdmin() || iAmAdmin() || iAmAccountManager() || iAmDsc() || iAmLeadOps() || iAmScheduler();
      case perms.unlock_users:
        return iAmMasterAdmin() || iAmAdmin() || iAmAccountManager() || iAmDsc();
      case perms.create_user:
        // turn the plain object into a User model
        // so we can use the custom methods
        const userToCreate = toUserModel(subject);
        // one can only create same or lower ranking users
        if (authUser.userRoleId > userToCreate.userRoleId) {
          return false;
        }
        // even if, in the hierarchy, account managers are above DCS, they shouldn't be able to create them
        // so we need a special case
        if (iAmAccountManager()) {
          return userToCreate.isDealerType();
        }
        if (iAmScheduler()) {
          return !userToCreate.isNotaryType();
        }
        // special case for lead ops
        // if (iAmLeadOps()) {
        //   return !userToCreate.isDealerType() && !userToCreate.isAccountManager();
        // }
        // by default, in all the other cases, users can create levels below themselves
        return true;
      case perms.edit_user:
        if (subject.id === authUser.id)
          // one can edit self
          return true;
        if (subject.userRoleId <= authUser.userRoleId) {
          // one can edit lower ranking users
          return false;
        }
        if (subject.isNotary() && iAmScheduler()) {
          // schedulers cannot create notaries by they can edit them
          return true;
        }
        // one can only edit what one can create
        return iAmGranted(perms.create_user, subject);
      case perms.change_user_role:
        // one can only change the role of lower ranking users
        if (subject.userRoleId <= authUser.userRoleId)
          return false;
        const user = toUserModel(subject);
        // notary is a special type of user and this role can be set only at creation
        if (user.isNotaryType())
          return false;
        return iAmMasterAdmin() || iAmAdmin();
      case perms.delete_users:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps();
      case perms.delete_user:
        if (subject.userRoleId <= authUser.userRoleId) {
          // one can only delete lower ranking users
          return false;
        }
        // one can only delete what one can create
        return iAmGranted(perms.create_user, subject);
      // dealer stores
      case perms.view_dealer_stores:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmAccountManager() || iAmDsc() || iAmScheduler();
      case perms.create_dealer_stores:
      case perms.edit_dealer_stores:
      case perms.create_dealer_store_users:
      case perms.create_dealer_store_signers:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmAccountManager() || iAmDsc();
      case perms.view_user_managed_stores:
      case perms.view_dealer_store_account_manager:
      case perms.assign_account_managers:
        return iAmMasterAdmin() || iAmAdmin() || iAmAccountManager();
      // dealer groups
      case perms.create_dealer_groups:
      case perms.edit_dealer_special_instructions:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmAccountManager() || iAmScheduler();
      case perms.edit_dealer_groups:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmAccountManager();
      case perms.edit_can_pay_with_check:
      case perms.edit_uses_coupa:
        return iAmMasterAdmin() || iAmAdmin() || iAmAccountManager();
      case perms.delete_dealer_groups:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps();
      // dealer regions
      case perms.create_dealer_regions:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmAccountManager() || iAmScheduler();
      case perms.edit_dealer_regions:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmAccountManager();
      case perms.delete_dealer_regions:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps();
      // finance docs
      case perms.upload_preview_documents:
        return iAmMasterAdmin() || iAmAdmin() || iAmScheduler();
      // supporting docs
      case perms.upload_supporting_documents:
      // edit order shipping info
      case perms.edit_order_shipping:
      // toggle rush service for order
      case perms.toggle_rush_service_for_orders:
      // notaries
      case perms.edit_notaries:
      case perms.add_order_accounting_review:
      case perms.add_order_duplicate_review:
      case perms.add_order_under_review:
      case perms.add_order_on_hold_review:
      case perms.resolve_order_on_hold_review:
        return iAmMasterAdmin() || iAmAdmin() || iAmScheduler() || iAmLeadOps();
      case perms.edit_notaries_QB:
      case perms.delete_notaries:
      case perms.view_notary_bidding_logs:
      case perms.edit_dealer_stores_QB:
      case perms.skip_vid:
      case perms.view_immediate_review_orders:
      case perms.view_similar_orders:
      case perms.view_payment_plans_filter:
      // sent statements to QB
      case perms.send_statements_QB:
      // quickbooks
      case perms.view_quickbooks:
      // performance reports
      case perms.view_scheduler_performance_reports:
      case perms.delete_support_case_comments:
      case perms.view_new_dealers_reports:
      // fees
      case perms.edit_additional_fees:
        return iAmMasterAdmin() || iAmAdmin();
      case perms.edit_appointment_date:
        return iAmLeadOps() || iAmScheduler()
      // orders
      case perms.assign_schedulers:
      case perms.resolve_order_accounting_review:
      case perms.resolve_order_duplicate_review:
      case perms.resolve_order_under_review:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps();
      case perms.assign_notaries:
      case perms.review_notaries:
      case perms.edit_orders:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmScheduler() || iAmDsc();
      case perms.view_biddings:
        return iAmScheduler() || iAmLeadOps();
      case perms.view_order_accounting:
      case perms.edit_order_accounting:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmAccountManager() || iAmScheduler();
      case perms.export_order_seal:
      case perms.resubmit_vid_requests:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps();
      case perms.view_pending_review_orders:
      case perms.view_similar_orders:
        return iAmMasterAdmin() || iAmAdmin();
      case perms.refresh_order_statuses:
        return iAmMasterAdmin() || iAmAdmin() || iAmScheduler() || iAmLeadOps();
      case perms.delete_vid_requests:
        return iAmMasterAdmin();
      // order internal statuses
      case perms.view_order_internal_status:
        return iAmMasterAdmin() || iAmAdmin() || iAmScheduler() || iAmLeadOps();
      case perms.delete_notary_credentials:
      // add order services - rush/ add vid/ remove vid
      case perms.add_order_services:
      case perms.check_user_email_alert:
        return iAmAdminType();
      // payments
      case perms.view_payments:
      case perms.view_statements:
      case perms.view_order_notary_status:
      case perms.retry_order_payments:
        return iAmMasterAdmin() || iAmAdmin() || iAmAccountManager();
      case perms.cancel_order:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmScheduler() || iAmDsc();
      case perms.delete_order:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps();
      case perms.mark_orders_as_ready_for_billing:
        return iAmMasterAdmin() || iAmAdmin() || iAmAccountManager() || iAmLeadOps() || iAmScheduler();
      // group usage reports
      case perms.view_group_usage_reports:
      case perms.view_dealer_sales_reports:
        return iAmMasterAdmin() || iAmAdmin()
          || iAmDealerGroupManager() || iAmDealerRegionalManager() || iAmDealerStoreManager();
      case perms.edit_support_cases:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmDsc();
      case perms.create_support_case_comments:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmDsc() || iAmAccountManager() || iAmScheduler();
      // calls
      case perms.create_customer_calls:
      case perms.create_notary_calls:
      // support cases
      case perms.view_own_scheduled_support_cases:
      // rating comments
      case perms.edit_notary_rating_comments:
        return iAmScheduler();
      case perms.view_own_dcs_support_cases:
        return iAmDsc();
      // payment plans
      case perms.create_payment_plans:
      case perms.edit_payment_plans:
      case perms.delete_payment_plans:
      case perms.extend_free_trials:
      case perms.edit_notary_rating:
        return iAmMasterAdmin();
      case perms.edit_store_payment_plan:
        return iAmMasterAdmin() || iAmAdmin() || iAmAccountManager();
      // messages
      case perms.view_messages:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmAccountManager() || iAmScheduler();
      // support cases
      case perms.assign_support_cases:
        return iAmAdmin() || iAmDsc();
      case perms.mark_orders_as_rural:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps();
      // accounting status
      case perms.edit_order_accounting_status:
      //  remove notary from an order
      case perms.remove_notary:
        return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmScheduler();
      // coupa info
      case perms.edit_coupa_information:
        return iAmAdminType();
      // chat
      case perms.view_message_channel:
        switch (subject.channelId) {
          case Message.CHANNEL_DEALER_CUSTOMER:
            return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmScheduler() || iAmDsc() || iAmAccountManager();
          case Message.CHANNEL_SUPPORT_DEALER:
            return iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmScheduler() || iAmDsc() || iAmAccountManager();
          case Message.CHANNEL_SUPPORT_DEALER_CUSTOMER:
            return iAmAdminType();
          case Message.CHANNEL_SCHEDULER_CUSTOMER:
            return subject.isNotaryRequired && (iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmScheduler() || iAmDsc() || iAmScheduler() || iAmAccountManager());
          case Message.CHANNEL_SCHEDULER_NOTARY:
            return subject.isNotaryRequired && (iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmScheduler() || iAmDsc() || iAmScheduler() || iAmAccountManager());
          case Message.CHANNEL_NOTARY_CUSTOMER:
            return subject.isNotaryRequired && (iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmScheduler() || iAmDsc() || iAmAccountManager());
          case Message.CHANNEL_MY_NOTES:
            return iAmMasterAdmin() || iAmAdmin();
          case Message.CHANNEL_INTERNAL_NOTES:
            return iAmMasterAdmin() || iAmAdmin() || iAmScheduler() || iAmLeadOps();
          case Message.CHANNEL_SCHEDULER_DEALER:
            return subject.isNotaryRequired && (iAmMasterAdmin() || iAmAdmin() || iAmLeadOps() || iAmAccountManager() || iAmDsc() || iAmScheduler());
        }
        return false;
      case perms.view_leaderboard_list:
        return iAmMasterAdmin() || iAmAdmin() || iAmScheduler() || iAmLeadOps();
      case perms.view_cron_jobs:
      case perms.view_error_codes:
      case perms.view_system_processes:
      case perms.view_route_one_logs:
        return iAmMasterAdmin();
      case perms.impersonate_user:
        return iCanImpersonate(subject);
      default:
        return false;
    }
  }

  const iAmNotGranted = (attribute, subject) => !iAmGranted(attribute, subject);

  const iAmGrantedAny = (attributes, subject) => attributes.some(attr => iAmGranted(attr, subject));

  const getUserAppUrl = fragment => {
    if (iAmDealerType()) {
      return getDealerAppUrl(fragment);
    } else if (iAmNotaryType()) {
      return getNotaryAppUrl(fragment);
    }
  }

  const iCanImpersonate = user => {
    // turn the plain object into a User model
    // so we can use the custom methods
    user = toUserModel(user);

    // one can only impersonate levels below himself
    // not above and not the same level
    if (authUser.userRoleId >= user.userRoleId) {
      return false;
    }
    if (iAmMasterAdmin() || iAmAdmin()) {
      return true;
    }
    if (iAmLeadOps()) {
      return user.isScheduler() || user.isDealerStoreUser() || user.isNotary();
    }
    if (iAmScheduler()) {
      return user.isDealerStoreUser() || user.isNotary();
    }
    if (iAmAccountManager() || iAmDsc()) {
      return user.isExternalType();
    }
    return false;
  }

  const contextValue = {
    iAmGranted,
    iAmNotGranted,
    iAmGrantedAny,
    iAmMasterAdmin,
    iAmAdmin,
    iAmDsc,
    iAmAccountManager,
    iAmScheduler,
    iAmLeadOps,
    iAmDealerGroupManager,
    iAmDealerRegionalManager,
    iAmDealerStoreManager,
    iAmDealerFinanceManager,
    iAmDealerSalesManager,
    iAmDealerUpperManager,
    iAmDealerLowerManager,
    iAmDealerManager,
    iAmDealerStoreUser,
    iAmAdminType,
    iAmDealerType,
    getUserAppUrl,
  };

  return <AccessContext.Provider value={contextValue} {...props} />
}

// helper hook that makes context data available
export const useAccess = () => React.useContext(AccessContext);

export default AccessProvider;