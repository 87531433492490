import Order from "model/order";
import OrderSigner from "model/orderSigner";
import { formats, formatTimestamp } from "./dateHelper";
import OrderDoc from "model/orderDoc";
import { capitalize } from "./utilHelper";

export const getOrderFieldLabel = field => {
  switch (field) {
    case 'docsSentToNotaryTs':
      return 'Documents sent to notary';
    default:
      return modelFieldToLabel(field);
  }
}

export const getOrderFieldValue = (field, value, defaultValue) => {
  switch (field) {
    case 'docDeliveryOption':
      return !!value ? Order.getDocDeliveryOptionName(value) : defaultValue;
    case 'docsSentToNotaryTs':
      return !!value ? tsToDate(value) : defaultValue;
    case 'shippingCompany':
      return !!value ? Order.getShippingCompanyName(value) : defaultValue;
    case 'notaryMustUploadDocs':
      return boolToYesNo(value, defaultValue);
    default:
      return value || defaultValue;
  }
}

export const getOrderVehicleFieldLabel = field => {
  switch (field) {
    case 'dmsDealId':
      return 'DMS Deal ID';
    case 'stockNum':
      return 'Stock Number';
    case 'vin':
      return 'V.I.N. #';
    case 'yearMakeModel':
      return 'Year, make, model';
    default:
      return modelFieldToLabel(field);
  }
}

export const getOrderDocFieldLabel = field => {
  switch (field) {
    default:
      return modelFieldToLabel(field);
  }
}

export const getOrderDocFieldValue = (field, value, defaultValue) => {
  switch (field) {
    case 'isUploaded':
      return boolToYesNo(value, defaultValue);
    case 'fields':
      return !!value ? value.length : 0;
    case 'status':
      return OrderDoc.getStatusName(value);
    case 'statusChangedTs':
      return !!value ? tsToDateTime(value) : defaultValue;
    default:
      return value || defaultValue;
  }
}

export const getOrderSignerFieldLabel = field => {
  switch (field) {
    default:
      return modelFieldToLabel(field);
  }
}

export const getSignerTypeLabel = orderSigner => orderSigner.type === OrderSigner.TYPE_DEALER ? 'dealer signer' : (orderSigner.position > 1 ? 'additional customer' : 'customer');

const modelFieldToLabel = field => {
  // add a space before each capital letter
  let label = field.replace(/([A-Z])/g, ' $1');
  // remote ' Ts' at the end of timestamps
  label = label.replace(/\sTs$/g, '');
  // make the label lowercase
  label = label.toLowerCase();
  // capitalize the first letter
  label = capitalize(label);
  return label;
}

const boolToYesNo = (value, defaultValue) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  return defaultValue;
}

const tsToDate = value => formatTimestamp(value, formats.DATE);

const tsToDateTime = value => formatTimestamp(value, formats.DATETIME);