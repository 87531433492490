import React from "react";
import { useCountdown } from "hooks/useCountdown";
import PropTypes from "prop-types";

const addLeadingZero = value => value < 10 ? `0${value}` : value;

const CountdownTimer = ({ targetDate, stateChanger, isBiddingCounter }) => {
  const [hours, minutes, seconds] = useCountdown(targetDate);

  if (hours + minutes + seconds === 0) {
    stateChanger();
  }

  if (hours + minutes + seconds <= 0) {
    if (isBiddingCounter) {
      return (
        <div className="show-counter">
          <i className="mdi mdi-scale-balance font-size-24 me-3"></i>
          <div className="me-1 font-weight-400">
            Bidding closed
          </div>
        </div>
      );
    } else {
      return " a few seconds...";
    }
  }

  if (isBiddingCounter) {
    return (
      <div className="show-counter">
        <i className="mdi mdi-scale-balance font-size-24 me-3"></i>
        <div className="me-1 font-weight-400">
          Bidding closing in
        </div>
        {
          hours > 0 &&
          <div className="bidding-countdown">
            <p>{hours}h</p>
          </div>
        }
        {
          minutes === 0
            ?
            <div className="bidding-countdown">
              <p>{seconds}s</p>
            </div>
            :
            <div className="bidding-countdown">
              <p>{minutes}m</p>
            </div>
        }
      </div>
    );
  } else {
    return (
      <>
        <div className="show-counter">
          <i className="bx bx-time-five text-primary font-size-20 ms-1"></i>
          <div className="countdown">
            <p>{addLeadingZero(minutes)}</p>
          </div>
          <p>:</p>
          <div className="countdown">
            <p>{addLeadingZero(seconds)}</p>
          </div>
        </div>
      </>
    );
  }
};

CountdownTimer.propTypes = {
  targetDate: PropTypes.number,
  stateChanger: PropTypes.any,
  isBiddingCounter: PropTypes.bool
}

export default CountdownTimer;

