import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, } from "react-router-dom"
import { Card, CardBody, Row, Col, Alert, Button, Input } from "reactstrap"
import { getInitialsFromName, getSharedTableOptions, showError, showSuccess, showWarning } from "helpers/utilHelper"
import { assignOrderScheduler, doOrderSchedulerAssignmentCleanup, doUserDtCleanup, getSchedulerDt } from "store/actions";
import { getSharedPaginationOptions } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import config from 'config';
import FiltersSchedulers from '../Filters/Schedulers';
import UserAvatar from 'components/Shared/UserAvatar';
import { omit } from 'lodash';
import Confirmation from 'components/Shared/Confirmation';
import { ServerErrorException, UNABLE_SEND_SCHEDULER_ASSIGNED_TO_ORDER_NOTIF } from 'helpers/errorHelper';
import { useDebounce } from 'hooks/debounce';

const DataTableSchedulers = ({ order }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /********** STATE **********/

  const initialFilters = {
    name: '',
    email: '',
    _set: false,
  };

  const { users: rows, usersError: rowsError, totalCount, listParams, isLoadInProgress } = useSelector(state => state.User.Dt);
  const { assigned, assignError } = useSelector(state => state.Order.Scheduler);
  const [filters, setFilters] = useState(initialFilters);
  const [userToAssign, setUserToAssign] = useState(null);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState(listParams.search);

  // Debounce the value of the search
  const debouncedSearch = useDebounce(searchTerm, config.DATA_TABLE_SEARCH_DELAY);

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    defaultSorted: [{
      dataField: listParams.sortBy,
      order: listParams.sortDir,
    }],
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    return () => {
      dispatch(doUserDtCleanup());
      dispatch(doOrderSchedulerAssignmentCleanup());
    }
  }, []);

  // runs whenever 'totalCount' changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let's update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  // runs whenever 'filters' changes
  // which happens after 'apply-filters' or 'clear-filters'
  // but also on component mount
  useEffect(() => {
    if (!filters._set) {
      return;
    }
    // refresh the list data based on the new filters
    dispatch(getSchedulerDt({
      ...listParams,
      filters: omit(filters, '_set'),
      // reset the page number when filtering
      // otherwise the current page number might be higher than the total number of pages after the filtering
      page: 1,
    }));
    // update the pagination with the new page number
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [filters]);

  // search is done manually, due to debouncing
  useEffect(() => {
    // '_set' is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty('_set') && !filters._set) {
      return;
    }
    // reset the page number when searching
    // otherwise the current page number might be higher than the total number of pages after the search
    dispatch(getSchedulerDt({
      ...listParams,
      page: 1,
      search: searchTerm,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [debouncedSearch]);

  useEffect(() => {
    if (assigned === true) {
      showSuccess('Scheduler has been assigned');
      navigate(route(routes.view_order, order.id));
    } else if (assigned === false) {
      if (assignError instanceof ServerErrorException) {
        if (assignError.code == UNABLE_SEND_SCHEDULER_ASSIGNED_TO_ORDER_NOTIF) {
          // user has been assigned but the notifications could not be sent (at least some of them)
          showWarning('Unable to send notifications');
          navigate(route(routes.view_order, order.id));
          return;
        }
      }
      showError('Unable to assign scheduler');
      setUserToAssign(null);
    }
  }, [assigned]);

  /********** EVENT HANDLERS **********/

  // runs whenever table params change (sorting, pagination, etc)
  const handleTableChange = (type, newState) => {
    if (!filters._set) {
      return;
    }
    // refresh the list data based on the new table params
    dispatch(getSchedulerDt({
      ...listParams,
      sortBy: newState.sortField,
      sortDir: newState.sortOrder,
      pageSize: newState.sizePerPage,
      page: newState.page,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  const handleClearFilters = () => setFilters({ ...initialFilters, _set: true });

  const confirmAssignUser = user => {
    setUserToAssign(user);
    setIsConfirmationVisible(true);
  }

  const assignScheduler = () => {
    setIsConfirmationVisible(false);
    dispatch(assignOrderScheduler({ userId: userToAssign.id }, order.id));
  }

  return <React.Fragment>
    <Card className="paginated-table-card">
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField='id'
              columns={columns(order.schedulerId, confirmAssignUser)}
              data={rows}>
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col>
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <Input type="text" onChange={e => setSearchTerm(e.target.value)} placeholder="Search" value={searchTerm} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div className="text-end">
                        <FiltersSchedulers filters={filters} applyFilters={setFilters} clearFilters={handleClearFilters} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          noDataIndication={!rowsError && "No users found"}
                          onTableChange={handleTableChange}
                          defaultSorted={paginationProps.defaultSorted}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load users
                      </Alert>}
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Assign"
      reverseButtons={false}
      onConfirm={() => assignScheduler()}
      onCancel={() => {
        setUserToAssign(null);
        setIsConfirmationVisible(false);
      }}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to assign scheduler &quot;{userToAssign.fullName}&quot; to order #{order.id}?</span>
    </Confirmation>}
  </React.Fragment>
}

const columns = (schedulerId, confirmAssignUser) => [{
  // this column is not used but we need it for the default sorting to work
  // if dataField does not match a defined column, it will be ignored
  dataField: 'id',
  sort: true,
  hidden: true,
}, {
  dataField: 'fullName',
  text: 'Name',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <React.Fragment>
    <UserAvatar
      id={row.id}
      image={row.image}
      initials={getInitialsFromName(cellContent)}
      size="sm"
      className="in-table me-3" />
    <Link to={route(routes.view_user, row.id)}>{cellContent}</Link>
  </React.Fragment>,
}, {
  dataField: 'email',
  text: 'Email',
  sort: true,
}, {
  dataField: 'numSignings',
  text: 'No of In Progress Signings',
  sort: true,
}, , {
  dataField: 'numRural',
  text: 'No of In Progress Rural Orders',
  sort: true,
}, {
  dataField: 'dummy',
  text: 'Action',
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    if (row.id == schedulerId) {
      return <i className="mdi mdi-check-decagram mdi-24px text-success"></i>
    }
    return <Button type="button" color="primary" onClick={() => confirmAssignUser(row)}>Assign</Button>;
  },
}];

DataTableSchedulers.propTypes = {
  order: PropTypes.object,
};

export default DataTableSchedulers;
