import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getFullOrder } from 'store/actions';
import NotaryAppointmentDate from '../Form/Edit/NotaryAppointmentDate';
import NotaryInfoView from '../View/NotaryInfoView';

const SectionNotaryInfo = ({ id, order }) => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);

  const refreshOrder = () => dispatch(getFullOrder(order.id));

  // toggles the edit mode on and off
  const toggleEditMode = () => {
    setIsEditModeActive(prevMode => !prevMode);
  }

  return <React.Fragment>
    {isEditModeActive && <NotaryAppointmentDate finishedHandler={toggleEditMode} defaultValues={order} id={id} refreshHandler={refreshOrder} />}
    {!isEditModeActive && <NotaryInfoView toggleEditMode={toggleEditMode} />}
  </React.Fragment >
}

SectionNotaryInfo.propTypes = {
  order: PropTypes.object,
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  isLocked: PropTypes.bool,
};

export default SectionNotaryInfo;