import React, { useEffect, useState } from "react";
import Col from "components/Shared/Col";
import { Row, Card, CardBody } from "reactstrap";
import CountdownTimer from "../CountdownTimer";
import PropTypes from "prop-types";

const BiddingTimer = ({ targetTime, stateChanger }) => {
  const isVisible = () => {
    const target = document.querySelector("#sealing_card");
    const bottom = target?.getBoundingClientRect().bottom || 0;
    return bottom >= 0 && bottom <= window.innerHeight
  }

  const [toast, setToast] = useState(!isVisible());

  /********** HANDLERS **********/
  const showToast = () => {
    setToast(true);
  };

  const hideToast = () => {
    setToast(false);
  };

  /********** EFFECTS **********/
  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return () => document.removeEventListener("scroll", scrollHandler);
  }, []);


  const scrollHandler = () => {
    isVisible() ? hideToast() : showToast();
  };

  return <>
    <Col className="text-end">
      <Card id="sealing_card" className="text-start d-inline-block bg-primary text-white">
        <CardBody>
          <Row>
            <Col className="d-flex align-items-center">
              <div className="card-title mb-0">
                <CountdownTimer targetDate={targetTime} isBiddingCounter stateChanger={stateChanger} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
    {toast && (<div className="seal-toast-wrapper">
      <Card id="sealing_toast" className="text-start d-inline-block bg-primary text-white">
        <CardBody>
          <Row>
            <Col className="d-flex align-items-center">
              <div className="card-title mb-0">
                <CountdownTimer targetDate={targetTime} isBiddingCounter stateChanger={stateChanger} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>)}
  </>
}

BiddingTimer.propTypes = {
  targetTime: PropTypes.number,
  stateChanger: PropTypes.any,
};

export default BiddingTimer;