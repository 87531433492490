import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { Container, Row, Col, Card, CardHeader, CardBody, Table } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import { getRouteOneLog } from "helpers/backendHelper";
import classnames from "classnames";
import { formats, formatTimestamp } from "helpers/dateHelper";

const ViewRouteOneLog = () => {

  const { id } = useParams();

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [log, setLog] = useState(null);
  const [logError, setLogError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    refreshLog();
  }, [id]);

  /********** OTHER **********/

  const refreshLog = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getRouteOneLog(id)
      .then(response => {
        setLog(response.r1Log);
      })
      .catch(ex => {
        setLogError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.view_route_one_logs) && <div className="page-content">
      {!!log && <React.Fragment>
        <MetaTitle>{id} | Route One Logs</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(log)} />
          <Row>
            <Col xs="6">
              <Card className="vehicle-information expand-v overflow-hidden">
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title mt-2 mb-0">Log Information</div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="px-0 pb-0">
                  <div className={classnames("card-section")}>
                    <Table className={classnames("section-rows table")}>
                      <tbody>
                        <tr>
                          <td className="section-row-label">ID</td>
                          <td className="section-row-value">{log._id}</td>
                        </tr>
                        <tr>
                          <td className="section-row-label">Error Code</td>
                          <td className="section-row-value">{log.error.code}</td>
                        </tr>
                        <tr>
                          <td className="section-row-label">Error Name</td>
                          <td className="section-row-value">{log.error.name}</td>
                        </tr>
                        <tr>
                          <td className="section-row-label">Dealer Store Id</td>
                          <td className="section-row-value">{log.dealerStoreId || "--"}</td>
                        </tr>
                        <tr>
                          <td className="section-row-label">Date</td>
                          <td className="section-row-value">{formatTimestamp(log.timestamp, formats.DATETIME)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="vehicle-information expand-v overflow-hidden">
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title mt-2 mb-0">Body Object</div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="px-0 pb-0">
                  <div className={classnames("card-section")}>
                    <pre>
                      {JSON.stringify(log.rawBody, null, 4)}
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {
            !!log.error.exception &&
            <Row>
              <Col>
                <Card className="vehicle-information expand-v overflow-hidden">
                  <CardHeader className="bg-transparent pt-3 pb-0">
                    <Row>
                      <Col>
                        <div className="card-title mt-2 mb-0">Exception Object</div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="px-0 pb-0">
                    <div className={classnames("card-section")}>
                      <pre>
                        {JSON.stringify(log.error.exception, null, 4)}
                      </pre>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !log && <Preloader className="inner" />}
      {logError && <Error error={logError} title404="Log not found" />}
    </div>}
    {iAmNotGranted(perms.view_route_one_logs) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = log => [{
  title: 'Route One Logs',
  url: route(routes.list_route_one_logs),
}, {
  title: log._id,
}];

export default ViewRouteOneLog;
