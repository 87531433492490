import React from 'react';
import PropTypes from 'prop-types';

const Video = ({url}) => {
  return(<iframe
    src={`${url}&autoplay=0&showinfo=0&modestbranding=1&rel=0`}
    title="RouteOne video player"
    allowFullScreen
    allow="autoplay; encrypted-media"
    width="728px"
    height="300px"
    style={{ border: 0 }}
  />)
}

Video.propTypes = {
  url: PropTypes.string,
}

export default Video;