import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';
import ExpanderToggle from '../ExpanderToggle';
import ExpanderDetails from '../ExpanderDetails';
import { formatIpAddress } from 'helpers/utilHelper';

const OrderSignerVerificationCreated = props => {

  const { log } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  return <React.Fragment>
    <div className="d-flex">
      <AuthorAvatar log={log} />
      <div className="flex-grow-1">
        {log.isResend && <>Security code [<strong>{log.subject.code}</strong>] was resent to <AuthorName log={log} />.</>}
        {!log.isResend && <>Security code [<strong>{log.subject.code}</strong>] was generated for <AuthorName log={log} />.</>}
        <p className="mb-0">
          <EventTime log={log} showFull={true} />
          <ExpanderToggle {...props} {...{ isExpanded, setIsExpanded }} />
        </p>
      </div>
    </div>
    <ExpanderDetails {...props} {...{ isExpanded }}>
      <Table className="table-borderless mb-0 w-auto">
        <tbody>
          <tr>
            <th className="pt-2 pb-0">IP Address</th>
            <td className="pt-2 pb-0">{formatIpAddress(log.subject.ip)}</td>
          </tr>
          <tr>
            <th className="pt-2 pb-0">Browser</th>
            <td className="pt-2 pb-0">{log.subject.browser}</td>
          </tr>
          <tr>
            <th className="pt-2 pb-0">Operating System</th>
            <td className="pt-2 pb-0">{log.subject.os}</td>
          </tr>
          <tr>
            <th className="pt-2 pb-0">Platform</th>
            <td className="pt-2 pb-0">{log.subject.platform}</td>
          </tr>
        </tbody>
      </Table>
    </ExpanderDetails>
  </React.Fragment>
}

OrderSignerVerificationCreated.propTypes = {
  log: PropTypes.object,
}

export default OrderSignerVerificationCreated;