import React from 'react';
import PropTypes from 'prop-types';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';

const OrderFeeDeleted = props => {

  const { log } = props;

  const appliesToLabel = !!log.subject.dealerStoreId ? 'dealer' : 'notary';

  return <div className="d-flex">
    <AuthorAvatar log={log} />
    <div className="flex-grow-1">
      <AuthorName log={log} /> deleted {appliesToLabel} fee <b>{log.subject.additionalFeeName}</b>.
      <p className="mb-0"><EventTime log={log} /></p>
    </div>
  </div>
}

OrderFeeDeleted.propTypes = {
  log: PropTypes.object,
}

export default OrderFeeDeleted;