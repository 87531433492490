import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, } from "react-router-dom";
import { Card, CardBody, Row, Col, Alert, Input } from "reactstrap";
import { getDtFooterRowCount, getSharedTableOptions } from "helpers/utilHelper";
import { getSharedPaginationOptions } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import { doSupportCaseDtCleanup, getSupportCaseDt, setSupportCaseDtActiveTab } from "store/actions";
import { formats, formatTimestamp, subtractHoursFromNow, timeSinceShort } from "helpers/dateHelper";
import { clearSupportCaseDtFilters } from "store/actions";
import DataTableFilters from "./DataTableFilters";
import config from "config";
import SupportCase from "model/supportCase";
import { useSocketOn, useSubscribeToAllSupportCases } from 'hooks/socket';
import socketEvent from 'constants/socketEvent';
import DataTableTabs from "./DataTableTabs";
import { useAuth } from "context/auth";
import { getSupportCaseServiceWithIcon } from 'helpers/jsxHelper';
import { omit } from 'lodash';
import { perms, useAccess } from 'context/access';
import { useDebounce } from 'hooks/debounce';

const DataTable = () => {

  const { iAmGranted } = useAccess();

  const dispatch = useDispatch();
  // get user info
  const { user } = useAuth();

  /********** STATE **********/

  const { supportCases: rows, supportCasesError: rowsError, totalCount, listParams, isLoadInProgress, activeTab } = useSelector(state => state.SupportCase.Dt);
  const filters = useSelector(state => state.SupportCase.DtFilters);
  const [searchTerm, setSearchTerm] = useState(listParams.search);

  // Debounce the value of the search
  const debouncedSearch = useDebounce(searchTerm, config.DATA_TABLE_SEARCH_DELAY);

  const tabFilters = {
    all: {},
    my: iAmGranted(perms.view_own_scheduled_support_cases) ? {} : {
      assigneeId: user.id,
    },
    recent: {
      recent: config.SUPPORT_CASE_RECENT_THRESHOLD,
    },
    flagged: {
      flagged: config.SUPPORT_CASE_FLAG_THRESHOLD,
    },
  };

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    defaultSorted: [{
      dataField: listParams.sortBy,
      order: listParams.sortDir,
    }],
  });

  /********** OTHER **********/

  // reloads the table with the same parameters
  const reloadTable = useCallback(() => dispatch(getSupportCaseDt(listParams)), [listParams]);

  /********** SOCKET **********/

  // start receiving support case updates
  useSubscribeToAllSupportCases();

  const onSupportCaseChanged = useCallback(reloadTable, [reloadTable]);

  // listen for changes on support cases
  useSocketOn(socketEvent.supportCaseChanged, onSupportCaseChanged);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // we do not get the list data here
    // instead we listen for changes on 'filters' state var and do it there (see below)
    // this is to avoid fetching the data twice (both on component mount and on filters changed)
    return () => {
      // state cleanup on component unmount
      dispatch(doSupportCaseDtCleanup());
    }
  }, []);

  // runs whenever 'totalCount' changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let's update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  // runs whenever 'filters' changes
  // which happens after 'apply-filters' or 'clear-filters'
  // but also on component mount
  useEffect(() => {
    // "_set" is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty("_set") && !filters._set) {
      return;
    }
    // refresh the list data based on the new filters
    dispatch(getSupportCaseDt({
      ...listParams,
      filters: { ...omit(filters, "_set"), ...tabFilters[activeTab] },
      // reset the page number when filtering
      // otherwise the current page number might be higher than the total number of pages after the filtering
      page: 1,
    }));
    // update the pagination with the new page number
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [filters, activeTab]);

  // search is done manually, due to debouncing
  useEffect(() => {
    // '_set' is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty('_set') && !filters._set) {
      return;
    }
    // reset the page number when searching
    // otherwise the current page number might be higher than the total number of pages after the search
    dispatch(getSupportCaseDt({
      ...listParams,
      page: 1,
      search: searchTerm,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [debouncedSearch]);


  const handleTabChange = (tab) => {
    dispatch(clearSupportCaseDtFilters());
    toggleActiveTab(tab);
  };
  /********** EVENT HANDLERS **********/

  // Toggle active tab
  const toggleActiveTab = tab => {
    // Reset table data
    dispatch(doSupportCaseDtCleanup());
    dispatch(setSupportCaseDtActiveTab(tab));
  };

  // runs whenever table params change (sorting, pagination, etc)
  const handleTableChange = (type, newState) => {
    // "_set" is a special flag we use to know if the default filter values have been initialized (ex. from url)
    // we want to ignore this event before the filters are initialized
    // therefore avoiding a duplicate call to the backend
    if (filters.hasOwnProperty("_set") && !filters._set) {
      return;
    }
    // refresh the list data based on the new table params
    dispatch(getSupportCaseDt({
      ...listParams,
      sortBy: newState.sortField,
      sortDir: newState.sortOrder,
      pageSize: newState.sizePerPage,
      page: newState.page,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  return <React.Fragment>
    <Card className="paginated-table-card">
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField='id'
              columns={getColumns(listParams.pageSize, listParams.page)}
              data={rows}>
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col>
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <Input type="text" onChange={e => setSearchTerm(e.target.value)} placeholder="Search" value={searchTerm} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div className="text-end">
                        <DataTableFilters />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DataTableTabs activeTab={activeTab} toggleActiveTab={handleTabChange} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          noDataIndication={!rowsError && "No cases found"}
                          onTableChange={handleTableChange}
                          defaultSorted={paginationProps.defaultSorted}
                          rowClasses={row => isFlagged(row) ? "bg-danger bg-soft" : undefined}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load cases
                      </Alert>}
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                        <div className="d-inline ms-2">{getDtFooterRowCount(paginationProps, rows.length, totalCount)}</div>
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  </React.Fragment>
}

const isFlagged = row => (subtractHoursFromNow(config.SUPPORT_CASE_FLAG_THRESHOLD / 3600) > row.updatedTs) && row.status < SupportCase.STATUS_RESOLVED

const getColumns = (pageSize, page) => ([{
  dataField: 'idx',
  text: '#',
  sort: false,
  formatter: (cellContent, row, rowIndex) => pageSize * (page - 1) + rowIndex + 1,
}, {
  dataField: 'customerFullName',
  text: 'Customer Name',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <Link to={route(routes.view_support_case, row.id)}>{cellContent}</Link>,
}, {
  dataField: 'id',
  text: 'Case ID',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => <Link to={route(routes.view_support_case, cellContent)}>{"#" + cellContent}</Link>,
}, {
  dataField: 'orderId',
  text: 'Order ID',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <Link to={route(routes.view_order, row.orderId)}>{cellContent}</Link>,
}, {
  dataField: 'statusName',
  text: 'Status',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    const color = SupportCase.getStatusColor(row.status);
    return <span className={`badge badge-lg rounded-pill bg-${color}`}>{cellContent}</span>;
  },
}, {
  dataField: 'priority',
  text: 'Priority',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => {
    const color = SupportCase.getPriorityColor(cellContent);
    return <><span className={`priority-circle bg-${color}`} />{SupportCase.getPriorityName(cellContent)}</>;
  },
}, {
  dataField: 'createdTs',
  text: 'Ticket Creation Time',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => formatTimestamp(cellContent, formats.DATETIME),
}, {
  dataField: 'orderSubmittedTs',
  text: 'Submitted Time',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => formatTimestamp(cellContent, formats.DATETIME),
}, {
  dataField: 'dummy1',
  text: 'Rsp Time',
  sort: false,
  isDummyField: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => row.firstResponseTs ? timeSinceShort(row.createdTs, row.firstResponseTs) : "--",
}, {
  dataField: 'services',
  text: 'Service',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent.length ? cellContent.map(id => getSupportCaseServiceWithIcon(id)) : "--",
}, {
  dataField: 'reporterFullName',
  text: 'Reported by',
  sort: true,
}, {
  dataField: 'assigneeFullName',
  text: 'Assigned to',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => cellContent ? <Link to={route(routes.view_user, row.assigneeId)}>{cellContent}</Link> : '--',
}, {
  dataField: 'updatedTs',
  text: '',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => isFlagged(row) ? <i className="fas fa-flag text-danger font-size-13" /> : "",
}]);

export default DataTable;