import React from "react";

const Banner = () => {
  return <div className="route-one-banner">
    <div className="description-wrapper">
      <h1 className="title">Get Started <br/>with RouteOne</h1>
      <div className="description">Discover how to streamline your order creation process with RouteOne. Follow the steps below to integrate your RouteOne account and start creating orders seamlessly.</div>
    </div>
  </div>
}

export default Banner;