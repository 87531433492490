import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, } from "react-router-dom"
import { Card, CardBody, Row, Col, Alert } from "reactstrap"
import { getDtFooterRowCount, getSharedTableOptions } from "helpers/utilHelper"
import { getSharedPaginationOptions } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import { getRouteOneLogsList } from "helpers/backendHelper";
import { formats, formatTimestamp } from "helpers/dateHelper";

const DataTable = () => {

  /********** STATE **********/

  const [rows, setRows] = useState([]);
  const [rowsError, setRowsError] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: 1,
    sizePerPage: 50,
    defaultSorted: [{
      dataField: 'id',
      order: 'desc',
    }],
  });

  /********** EFFECTS **********/

  // runs whenever 'totalCount' changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let's update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  // runs on component mount
  useEffect(() => {
    // refresh the list data based on the new filters
    getRouteOneLogsDt({
      sortBy: 'id',
      pageSize: 50,
      page: 1,
    });
    // update the pagination with the new page number
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, []);

  /********** EVENT HANDLERS **********/

  const getRouteOneLogsDt = (params) => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the logs data
    getRouteOneLogsList(params)
      .then(response => {
        setRows(response.r1Logs);
        setTotalCount(response.totalCount);
      })
      .catch(ex => {
        setRowsError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  // runs whenever table params change (sorting, pagination, etc)
  const handleTableChange = (type, newState) => {
    // refresh the list data based on the new table params
    getRouteOneLogsDt({
      sortBy: newState.sortField,
      sortDir: newState.sortOrder,
      pageSize: newState.sizePerPage,
      page: newState.page,
    });
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  return <React.Fragment>
    <Card className="paginated-table-card">
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField='_id'
              columns={columns}
              data={rows}>
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          noDataIndication={!rowsError && "No logs found"}
                          onTableChange={handleTableChange}
                          defaultSorted={paginationProps.defaultSorted}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load Route One logs
                      </Alert>}
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                        <div className="d-inline ms-2">{getDtFooterRowCount(paginationProps, rows.length, totalCount)}</div>
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  </React.Fragment>
}

const columns = [{
  dataField: '_id',
  text: "Id",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <React.Fragment>
    <Link to={route(routes.view_route_one_log, row._id)}>{cellContent}</Link>
  </React.Fragment>,
}, {
  dataField: 'errorCode',
  text: 'Error Code',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => row.error.code,
}, {
  dataField: 'errorName',
  text: 'Error Name',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => row.error.name,
}, {
  dataField: 'dealerStoreId',
  text: 'Dealer Store Id',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <React.Fragment>
    {
      !!cellContent
        ?
        <Link to={route(routes.view_dealer_store, cellContent)}>{cellContent}</Link>
        :
        "--"
    }
  </React.Fragment>,
}, {
  dataField: 'timestamp',
  text: 'Date',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => formatTimestamp(cellContent, formats.DATETIME),
}];

export default DataTable;
