import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';
import { route, routes } from 'helpers/routeHelper';
import { getLogAuthorFullName } from 'helpers/utilHelper';

const UserNotaryAppointmentAcceptedMailSent = props => {

  const { log } = props;

  return <div className="d-flex">
    <AuthorAvatar log={log} />
    <div className="flex-grow-1">
      <AuthorName log={log} /> sent email notification to <Link to={route(routes.view_user, log.subject.id)}><b>{getLogAuthorFullName(log.subject)}</b></Link> that a customer has been accepted the appointement.
      <p className="mb-0"><EventTime log={log} /></p>
    </div>
  </div>
}

UserNotaryAppointmentAcceptedMailSent.propTypes = {
  log: PropTypes.object,
}

export default UserNotaryAppointmentAcceptedMailSent;