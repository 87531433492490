export default class ActivityLog {

  // Order
  static EVENT_ORDER_CREATED = 100;
  static EVENT_ORDER_SUBMITTED = 101;
  static EVENT_ORDER_CANCELLED = 102;
  static EVENT_ORDER_DELETED = 103;
  static EVENT_ORDER_SEAL_STARTED = 105;
  static EVENT_ORDER_SEAL_FAILED = 106;
  static EVENT_ORDER_SEAL_SUCCESS = 107;
  static EVENT_ORDER_STATUS_CHANGED = 108;
  static EVENT_ORDER_CONTRACT_DETAILS_CHANGED = 109;
  static EVENT_ORDER_SCHEDULER_ASSIGNED = 110;
  static EVENT_ORDER_SHIPPING_CHANGED = 112;
  static EVENT_ORDER_MARKED_AS_READY_FOR_BILLING = 113;
  static EVENT_ORDER_IS_RURAL_CHANGED = 117;
  static EVENT_ORDER_RUSH_SERVICE_ADDED = 118;
  static EVENT_ORDER_RUSH_SERVICE_REMOVED = 119;
  static EVENT_ORDER_ADDED_VID_REQUEST = 120;
  static EVENT_ORDER_REMOVED_VID_REQUEST = 121;
  static EVENT_ORDER_IGNORED_ACCOUNTING = 122;
  static EVENT_ORDER_UNCANCELLED = 123;
  static EVENT_ORDER_TERMS_ACCEPTED = 124;
  static EVENT_ORDER_ADDED_ESIGN_SERVICE = 125;
  static EVENT_ORDER_REMOVED_ESIGN_SERVICE = 126;
  static EVENT_ORDER_ON_HOLD_REVIEW_STATUS_CHANGED = 127;
  static EVENT_ORDER_ACCOUNTING_REVIEW_STATUS_CHANGED = 128;
  static EVENT_ORDER_UNDER_REVIEW_STATUS_CHANGED = 129;
  static EVENT_ORDER_DUPLICATE_REVIEW_STATUS_CHANGED = 130;
  static EVENT_ORDER_SERVICES_SELECTED = 131;

  // OrderDoc
  static EVENT_ORDER_DOC_CREATED = 200;
  static EVENT_ORDER_DOC_UPDATED = 201;
  static EVENT_ORDER_DOC_SIGNED = 202;
  static EVENT_ORDER_DOC_ACCEPTED = 203;
  static EVENT_ORDER_DOC_REJECTED = 204;
  static EVENT_ORDER_DOC_DELETED = 205;
  static EVENT_ORDER_DOC_STATUS_CHANGED = 206;
  static EVENT_ORDER_DOC_SIGNED_REBUILT = 207;
  static EVENT_ORDER_DOC_FIELDS_RESET = 208;
  static EVENT_ORDER_DOC_VIEWED = 209;
  static EVENT_ORDER_DOC_DOWNLOADED = 210;
  static EVENT_ORDER_DOC_READY_TO_SIGN = 211;

  // OrderSigner
  static EVENT_ORDER_SIGNER_CREATED = 300;
  static EVENT_ORDER_SIGNER_UPDATED = 301;
  static EVENT_ORDER_SIGNER_DELETED = 302;
  static EVENT_ORDER_SIGNER_SMS_OPT_IN = 303;
  static EVENT_ORDER_SIGNER_SMS_OPT_OUT = 304;
  static EVENT_ORDER_SIGNER_NOTARY_APPOINTMENT_ACCEPTED_MAIL_SENT = 305;
  static EVENT_ORDER_SIGNER_NOTARY_APPOINTMENT_SET_MAIL_SENT = 306;
  static EVENT_ORDER_SIGNER_NOTARY_APPOINTMENT_SET_SMS_SENT = 307;
  static EVENT_ORDER_SIGNER_SMS_CONSENT_SENT = 308;
  static EVENT_ORDER_SIGNER_ORDER_CREATED_MAIL_SENT = 309;
  static EVENT_ORDER_SIGNER_ORDER_CREATED_SMS_SENT = 310;
  static EVENT_ORDER_SIGNER_ORDER_DOC_CREATED_MAIL_SENT = 311;
  static EVENT_ORDER_SIGNER_ORDER_DOC_CREATED_SMS_SENT = 312;
  static EVENT_ORDER_SIGNER_MEETING_CREATED_MAIL_SENT = 313;
  static EVENT_ORDER_SIGNER_VID_SERVICE_ADDED_MAIL_SENT = 314;
  static EVENT_ORDER_SIGNER_VID_SERVICE_ADDED_SMS_SENT = 315;
  static EVENT_ORDER_SIGNER_DOC_REJECTED_MAIL_SENT = 316;
  static EVENT_ORDER_SIGNER_DOC_REJECTED_SMS_SENT = 317;
  static EVENT_ORDER_SIGNER_PAGE_REJECTED_MAIL_SENT = 318;
  static EVENT_ORDER_SIGNER_PAGE_REJECTED_SMS_SENT = 319;

  // VidRequest
  static EVENT_VID_REQUEST_AUTO_STARTED = 400;
  static EVENT_VID_REQUEST_ASSIST_STARTED = 401;
  static EVENT_VID_REQUEST_AUTO_FAILED = 402;
  static EVENT_VID_REQUEST_ASSIST_FAILED = 403;
  static EVENT_VID_REQUEST_AUTO_SUCCESS = 404;
  static EVENT_VID_REQUEST_ASSIST_SUCCESS = 405;
  static EVENT_VID_REQUEST_SKIPPED = 406;
  static EVENT_VID_REQUEST_DELETED = 407;
  static EVENT_VID_REQUEST_CREATED = 408;
  static EVENT_VID_REQUEST_IMAGES_UPLOADED = 409;

  // Meeting
  static EVENT_MEETING_CREATED = 500;
  static EVENT_MEETING_STARTED = 501;
  static EVENT_MEETING_ENDED = 502;
  static EVENT_MEETING_PARTICIPANT_JOINED = 503;
  static EVENT_MEETING_PARTICIPANT_LEFT = 504;

  // OrderVehicle
  static EVENT_ORDER_VEHICLE_CREATED = 600;
  static EVENT_ORDER_VEHICLE_UPDATED = 601;
  static EVENT_ORDER_VEHICLE_DELETED = 602;

  // OrderNotary
  static EVENT_ORDER_NOTARY_ASSIGNED = 700;
  static EVENT_ORDER_NOTARY_DOCS_RECEIVED = 702;
  static EVENT_ORDER_NOTARY_APPOINTMENT_SET = 703;
  static EVENT_ORDER_NOTARY_APPOINTMENT_DECLINED = 704;
  static EVENT_ORDER_NOTARY_DOCS_PRINTED = 705;
  static EVENT_ORDER_NOTARY_DOCS_UPLOADED = 706;
  static EVENT_ORDER_NOTARY_ORDER_COMPLETED = 707;
  static EVENT_ORDER_NOTARY_APPOINTMENT_ACCEPTED = 708;
  static EVENT_ORDER_NOTARY_DELETED = 709;

  // Payment
  static EVENT_PAYMENT_REQUESTED = 800;
  static EVENT_PAYMENT_FAILED = 801;
  static EVENT_PAYMENT_COMPLETED = 802;

  // Notary
  static EVENT_NOTARY_SMS_OPT_IN = 900;
  static EVENT_NOTARY_SMS_OPT_OUT = 901;

  // User
  static EVENT_USER_NOTARY_BID_ACCEPTED_MAIL_SENT = 1100;
  static EVENT_USER_NOTARY_APPOINTMENT_DECLINED_MAIL_SENT = 1101;
  static EVENT_USER_SCHEDULER_ASSIGNED_TO_ORDER_MAIL_SENT = 1102;
  static EVENT_USER_NOTARY_APPOINTMENT_SET_MAIL_SENT = 1103;
  static EVENT_USER_MEETING_CREATED_MAIL_SENT = 1104;
  static EVENT_USER_NOTARY_DOCS_RECEIVED_MAIL_SENT = 1105;
  static EVENT_USER_NOTARY_BID_AWARDED_MAIL_SENT = 1106;
  static EVENT_USER_NOTARY_BID_AWARDED_SMS_SENT = 1107;
  static EVENT_USER_NOTARY_MUST_UPLOAD_DOCS_MAIL_SENT = 1108;
  static EVENT_USER_NOTARY_START_ORDER_MAIL_SENT = 1109;
  static EVENT_USER_ORDER_COMPLETED_BY_NOTARY_MAIL_SENT = 1110;
  static EVENT_USER_NOTARY_APPOINTMENT_ACCEPTED_MAIL_SENT = 1111;
  static EVENT_USER_NEW_NOTARY_BID_MAIL_SENT = 1112;
  static EVENT_USER_NOTARY_BID_EXPIRED_MAIL_SENT = 1113;
  static EVENT_USER_NOTARY_BID_REJECTED_MAIL_SENT = 1114;
  static EVENT_USER_TRACKING_INFO_ADDED_MAIL_SENT = 1115;
  static EVENT_USER_TRACKING_INFO_ADDED_SMS_SENT = 1116;
  static EVENT_USER_NEW_NOTARY_BID_SMS_SENT = 1117;
  static EVENT_USER_SMS_CONSENT_SENT = 1118;
  static EVENT_USER_DOC_REJECTED_MAIL_SENT = 1119;
  static EVENT_USER_DOC_REJECTED_SMS_SENT = 1120;
  static EVENT_USER_PAGE_REJECTED_MAIL_SENT = 1121;
  static EVENT_USER_PAGE_REJECTED_SMS_SENT = 1122;
  static EVENT_USER_NOTARY_APPOINTMENT_ACCEPTED_SMS_SENT = 1123;
  static EVENT_USER_NOTARY_APPOINTMENT_DECLINED_SMS_SENT = 1124;
  static EVENT_USER_NOTARY_BID_DECLINED_MAIL_SENT = 1125;
  static EVENT_USER_NOTARY_BID_REJECTED_SMS_SENT = 1126;

  // MutedChat
  static EVENT_MUTED_CHAT_ALL_MUTED = 1200;
  static EVENT_MUTED_CHAT_ALL_UNMUTED = 1201;
  static EVENT_MUTED_CHAT_CHANNEL_MUTED = 1202;
  static EVENT_MUTED_CHAT_CHANNEL_UNMUTED = 1203;

  // DealerStore
  static EVENT_DEALER_STORE_CREATED = 1300;
  static EVENT_DEALER_STORE_DELETED = 1301;
  static EVENT_DEALER_STORE_INFO_UPDATED = 1302;
  static EVENT_DEALER_STORE_CONTACT_UPDATED = 1303;
  static EVENT_DEALER_STORE_CONTACT2_UPDATED = 1304;
  static EVENT_DEALER_STORE_FINANCE_UPDATED = 1305;
  static EVENT_DEALER_STORE_BILLING_UPDATED = 1306;
  static EVENT_DEALER_STORE_PAYMENT_PLAN_CHANGED = 1307;
  static EVENT_DEALER_STORE_SUBSCRIPTION_CANCELLED = 1308;
  static EVENT_DEALER_STORE_MONTHLY_USAGE_REPORT_SENT = 1309;

  // DealerStorePaymentSetup
  static EVENT_DEALER_STORE_PAYMENT_SETUP_CREATED = 1400;
  static EVENT_DEALER_STORE_PAYMENT_SETUP_DELETED = 1401;
  static EVENT_DEALER_STORE_PAYMENT_SETUP_FINISHED = 1402;

  // OrderSignerVerification
  static EVENT_ORDER_SIGNER_VERIFICATION_CREATED = 1600; //314
  static EVENT_ORDER_SIGNER_VERIFICATION_CHECKED = 1601; //315

  // OrderFee
  static EVENT_ORDER_FEE_CREATED = 1700;
  static EVENT_ORDER_FEE_UPDATED = 1701;
  static EVENT_ORDER_FEE_DELETED = 1702;

  static getEventMap() {
    const map = {
      [this.EVENT_ORDER_CREATED]: 'Order created',
      [this.EVENT_ORDER_SUBMITTED]: 'Order submitted',
      [this.EVENT_ORDER_CANCELLED]: 'Order cancelled',
      [this.EVENT_ORDER_DELETED]: 'Order deleted',
    };
    // just in case we forget to add events to the map
    // we still want them listed with the name of the constant
    for (const prop in ActivityLog) {
      const key = ActivityLog[prop];
      if (Object.keys(map).includes(key.toString())) {
        continue;
      }
      map[key] = prop;
    }
    return map;
  }

}