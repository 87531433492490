import React from 'react';
import PropTypes from 'prop-types';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';
import OrderDoc from 'model/orderDoc';

const OrderDocReadyToSign = props => {

  const { log } = props;

  return <div className="d-flex">
    <AuthorAvatar log={log} />
    <div className="flex-grow-1">
      <AuthorName log={log} /> marked {OrderDoc.getTypeName(log.subject.type).toLowerCase()} document <b>{log.subject.name}</b> as ready to sign.
      <p className="mb-0"><EventTime log={log} /></p>
    </div>
  </div>
}

OrderDocReadyToSign.propTypes = {
  log: PropTypes.object,
}

export default OrderDocReadyToSign;