import React from 'react';
import PropTypes from 'prop-types';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';

const OrderIsRuralChanged = props => {

  const { log } = props;

  return <React.Fragment>
    <div className="d-flex">
      <AuthorAvatar log={log} />
      <div className="flex-grow-1">
        {!!log.author && <React.Fragment>
          <AuthorName log={log} /> has been changed order <strong>rural</strong>.
        </React.Fragment>}
        <p className="mb-0"><EventTime log={log} /></p>
      </div>
    </div>
  </React.Fragment>
}

OrderIsRuralChanged.propTypes = {
  log: PropTypes.object,
}

export default OrderIsRuralChanged;