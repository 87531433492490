import PropTypes from "prop-types";
import React, { useCallback, useState, useEffect } from "react";
import { Card, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Label } from "reactstrap";
import classnames from "classnames";
import DealerTabContent from "./Tabs/Dealer";
import NotaryTabContent from "./Tabs/Notary";
import QuickbooksTabContainer from "./Tabs/Quickbooks";
import CardPreloader from "components/Shared/CardPreloader";
import Confirmation from 'components/Shared/Confirmation';
import TextareaAutosize from "react-textarea-autosize";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ignoreAccounting } from "helpers/backendHelper";
import { useDispatch } from "react-redux";
import { getFullOrder } from "store/actions";
import { ORDER_ACCOUNTING_STATUS_INVALID_FOR_IGNORING_ACCOUNTING, ORDER_STATUS_INVALID_FOR_IGNORING_ACCOUNTING } from "helpers/errorHelper";
import { showError, showSuccess } from "helpers/utilHelper";
import Order from "model/order";

const SectionAccounting = ({ fees, isLoadInProgress, order, refreshHandler }) => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [ignoreStatementVisible, setIgnoreStatementVisible] = useState(false);

  const refreshOrder = useCallback(() => dispatch(getFullOrder(order.id)), [order.id]);

  useEffect(() => {
    refreshHandler();
  }, []);

  /********** FORM CONFIG **********/

  const formPendingReviewInitialValues = {
    accountingComment: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formPendingReviewInitialValues,
    validationSchema: Yup.object({
      accountingComment: Yup.string().trim(),
    }),
    onSubmit: values => {
      ignoreStatement(values);
    },
  });

  const showIgnoreConfirmation = () => setIgnoreStatementVisible(true);

  const ignoreStatement = data => {
    ignoreAccounting(order.id, data).then(res => {
      setIgnoreStatementVisible(false);
      showSuccess("Accounting ignored successfully");
      formik.resetForm();
      refreshOrder();
    }).catch(err => {
      if (err.code == ORDER_ACCOUNTING_STATUS_INVALID_FOR_IGNORING_ACCOUNTING) {
        showError("Accounting status invalid for ignoring accounting.");
        return;
      }
      if (err.code == ORDER_STATUS_INVALID_FOR_IGNORING_ACCOUNTING) {
        showError("Order status invalid for ignoring accounting.");
        return;
      }
      showError("Unable to ignore accounting");
    })
  }

  /************ OTHER ************/

  const getNavInfo = () => {
    let tabCounter = 0;
    let NavInfo = [
      {
        name: "Dealer",
        id: ++tabCounter,
        component: <DealerTabContent fees={fees?.filter(fee => !!fee.dealerStoreId)} ignoreStatement={showIgnoreConfirmation} refreshOrderHandler={refreshOrder} />
      }
    ];
    if (order?.notaries?.length) {
      for (const orderNotary of order?.notaries) {
        NavInfo.push({
          name: `Notary - ${orderNotary.notaryFullName}`,
          id: ++tabCounter,
          component: <NotaryTabContent fees={fees?.filter(fee => fee.notaryId === orderNotary.notaryId)} appliesTo={tabCounter} ignoreStatement={showIgnoreConfirmation} refreshOrderHandler={refreshOrder} orderNotary={orderNotary}></NotaryTabContent>
        });
      }
    }
    NavInfo.push({
      name: "Quickbooks",
      id: ++tabCounter,
      component: <QuickbooksTabContainer />
    })
    return NavInfo;
  }

  /********** EVENT HANDLERS **********/

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const generateNavItem = (item) => {
    return (
      <NavItem key={item.id} className={item.id.toString()}>
        <NavLink
          className={classnames({
            active: customActiveTab === item.id.toString(),
          })}
          onClick={() => {
            toggleCustom(item.id.toString());
          }}
        >
          {item.name}
        </NavLink>
      </NavItem>
    )
  }

  const generateNavContent = (item) => {
    return (
      <TabPane key={item.id} tabId={item.id.toString()}>
        {item.component}
      </TabPane>
    )
  }

  return <React.Fragment>
    <Card id="accounting" className="expand-v accounting-card">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <div className="card-title mt-2 mb-3 d-flex align-items-center">
          Accounting
          {order.accountingStatus == Order.ACCOUNTING_STATUS_IGNORED &&
            <span className="badge header-badge rounded-pill bg-danger font-size-13 ms-2">Ignored</span>}
        </div>
        <div className="mb-4">
          {order.accountingStatus == Order.ACCOUNTING_STATUS_IGNORED && order.accountingComment && <>
            <div className="mt-2 font-weight-semibold">Comment:</div>
            <div className="font-size-12">{order.accountingComment}</div>
          </>}
        </div>
        <Nav tabs className="nav-tabs-custom nav-justified">
          {
            getNavInfo().map(navItem => generateNavItem(navItem))
          }
        </Nav>
        <TabContent activeTab={customActiveTab}>
          {getNavInfo().map((tabItem) => generateNavContent(tabItem))}
        </TabContent>
      </CardHeader>
      {isLoadInProgress && <CardPreloader />}
    </Card>

    {/* Ignore Statement Modal */}
    {ignoreStatementVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 600 }}
      title="Ignore Statement"
      confirmBtnText="Confirm"
      cancelBtnText="Close"
      onConfirm={formik.handleSubmit}
      onCancel={() => { setIgnoreStatementVisible(false); formik.resetForm(); }}
      closeOnClickOutside={false}
      reverseButtons={false}
    >
      <div className="my-3 mx-4">
        <div className="text-start">
          <Label className="form-label">Comments</Label>
          <TextareaAutosize
            minRows={3}
            maxRows={7}
            className={classnames("form-control")}
            name="accountingComment"
            onChange={e => formik.setFieldValue("accountingComment", e.target.value)}
            value={formik.values.accountingComment}
          />
        </div>
      </div>
    </Confirmation>}
  </React.Fragment>
}

SectionAccounting.propTypes = {
  fees: PropTypes.array,
  isLoadInProgress: PropTypes.bool,
  isQbEnabled: PropTypes.bool,
  order: PropTypes.object,
  refreshHandler: PropTypes.func,
};

export default SectionAccounting;