import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Modal, TabContent, Nav, NavItem, NavLink, TabPane, Label } from "reactstrap";
import RatingTooltip from "react-rating-tooltip";
import Col from "components/Shared/Col";
import PropTypes from 'prop-types';
import classnames from "classnames";
import { addNotaryRating, doNotaryRatingCleanup, getFullOrder } from 'store/actions';
import { perms, useAccess } from "context/access";
import { nullsToEmptyStrings, showSuccess, showError } from "helpers/utilHelper";
import { updateNotaryRating } from 'helpers/backendHelper';

const NotaryRating = ({ isModalOpen, toggleModal }) => {

  const dispatch = useDispatch();
  const { iAmGranted, iAmNotGranted } = useAccess();

  const { order } = useSelector(state => state.Order.Single);

  const { saved } = useSelector(state => state.Notary.Rating);

  const [customActiveTab, setcustomActiveTab] = useState(0);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const selectedRating = order?.notaries[customActiveTab]?.myRating;

  const isNotaryRated = (index) => !!order?.notaries[index]?.myRating;
  const hasComments = !!selectedRating?.positiveComments && !!selectedRating?.negativeComments;
  const starStyle = {};

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      tabId: null,
      timing: 0,
      quality: 0,
      communication: 0,
      responsiveness: 0,
      availability: 0,
      positiveComments: '',
      negativeComments: '',
      ...nullsToEmptyStrings(selectedRating)
    },
    validationSchema: Yup.object({
      timing: Yup.number().required().min(1),
      quality: Yup.number().required().min(1),
      responsiveness: Yup.number().required().min(1),
      availability: Yup.number().required().min(1),
      communication: Yup.number().required().min(1),
    }),
    onSubmit: (values, { resetForm }) => { addRating(values); resetForm(); }
  });

  useEffect(() => {
    if (saved === true) {
      showSuccess("The review has been saved");
      refreshOrder();
    } else if (saved === false) {
      showError("Unable to save the review");
    }
  }, [saved]);

  useEffect(() => {
    return () => dispatch(doNotaryRatingCleanup());
  }, []);

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
      formik.setFieldValue('tabId', tab);
    }
  };

  const updateComments = () => {
    setIsSaveInProgress(true);
    updateNotaryRating(selectedRating.id, { ...formik.values })
      .then(() => {
        showSuccess('Comments edited');
        refreshOrder();
      })
      .catch((ex) => {
        showError('Unable to save comments');
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  const addRating = (values) => {
    dispatch(addNotaryRating(order?.notaries[customActiveTab].notaryId, { orderId: order.id, ...values }))
  }

  const refreshOrder = () => dispatch(getFullOrder(order.id));

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        centered
        className="modal-size">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Rate Notary</h5>
          <button type="button" onClick={toggleModal} className="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Nav tabs className="nav-tabs-custom nav-justified">
            {order?.notaries?.map((notary, key) => <>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === key,
                  })}
                  onClick={() => {
                    toggleCustom(key);
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">{notary.notaryFullName}</span>
                </NavLink>
              </NavItem>
            </>)}
          </Nav>
          <TabContent activeTab={customActiveTab} className="p-3">
            {order?.notaries?.map((notary, key) => <>
              <TabPane tabId={key}>
                <Row>
                  <Col xxl="2" className="align-stars">
                    <Label className="form-label mt-3 mb-0">Timing</Label>
                    <RatingTooltip
                      max={5}
                      name="timing"
                      defaultRating={notary.myRating?.timing}
                      disabled={isNotaryRated(key)}
                      onChange={rate => {
                        formik.setFieldValue('timing', rate);
                      }}
                      ActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-warning mb-2"
                          style={starStyle}
                        />
                      }
                      InActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-muted mb-2"
                          style={starStyle}
                        />
                      }
                    />
                  </Col>
                  <Col xxl="2" className="align-stars">
                    <Label className="form-label mt-3 mb-0">Quality of work</Label>
                    <RatingTooltip
                      max={5}
                      name='quality'
                      defaultRating={notary.myRating?.quality}
                      disabled={isNotaryRated(key)}
                      onChange={rate => {
                        formik.setFieldValue('quality', rate);
                      }}
                      ActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-warning mb-2"
                          style={starStyle}
                        />
                      }
                      InActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-muted mb-2"
                          style={starStyle}
                        />
                      }
                    />
                  </Col>
                  <Col xxl="2" className="align-stars">
                    <Label className="form-label mt-3 mb-0">Communication</Label>
                    <RatingTooltip
                      max={5}
                      name='communication'
                      defaultRating={notary.myRating?.communication}
                      disabled={isNotaryRated(key)}
                      onChange={rate => {
                        formik.setFieldValue('communication', rate);
                      }}
                      ActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-warning mb-2"
                          style={starStyle}
                        />
                      }
                      InActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-muted mb-2"
                          style={starStyle}
                        />
                      }
                    />
                  </Col>
                  <Col xxl="2" className="align-stars">
                    <Label className="form-label mt-3 mb-0">Responsiveness</Label>
                    <RatingTooltip
                      max={5}
                      name="responsiveness"
                      defaultRating={notary.myRating?.responsiveness}
                      disabled={isNotaryRated(key)}
                      onChange={rate => {
                        formik.setFieldValue('responsiveness', rate);
                      }}
                      ActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-warning mb-2"
                          style={starStyle}
                        />
                      }
                      InActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-muted mb-2"
                          style={starStyle}
                        />
                      }
                    />
                  </Col>
                  <Col xxl="2" className="align-stars">
                    <Label className="form-label mt-3 mb-0">Availability</Label>
                    <RatingTooltip
                      max={5}
                      name='availability'
                      defaultRating={notary.myRating?.availability}
                      disabled={isNotaryRated(key)}
                      onChange={rate => {
                        formik.setFieldValue('availability', rate);
                      }}
                      ActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-warning mb-2"
                          style={starStyle}
                        />
                      }
                      InActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-muted mb-2"
                          style={starStyle}
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xxl="6">
                    <Label className="form-label mt-3 mb-2">Positive comments</Label>
                    <textarea
                      className="input-large form-control"
                      value={notary.myRating?.positiveComments}
                      readOnly={iAmGranted(perms.edit_notary_rating_comments) ? notary.myRating?.positiveComments : isNotaryRated(key)}
                      id="message"
                      rows="3"
                      placeholder="Enter a message ..."
                      name="positiveComments"
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xxl="6">
                    <Label className="form-label mt-3 mb-2">Negative comments</Label>
                    <textarea
                      className="input-large form-control"
                      value={notary.myRating?.negativeComments}
                      readOnly={iAmGranted(perms.edit_notary_rating_comments) ? notary.myRating?.negativeComments : isNotaryRated(key)}
                      id="message"
                      rows="3"
                      placeholder="Enter a message ..."
                      name="negativeComments"
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
              </TabPane>
            </>)}
          </TabContent>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={toggleModal} className="btn btn-secondary " data-dismiss="modal">Close</button>
          {iAmGranted(perms.edit_notary_rating_comments) && (!isNotaryRated(customActiveTab) ?
            <button type="button" className="btn btn-primary" onClick={formik.handleSubmit} disabled={!formik.isValid || formik.isSubmitting}>Add Review<i className="mdi mdi-star-outline ms-1"></i></button> :
            <button type="button" className="btn btn-primary" onClick={updateComments} disabled={isSaveInProgress || hasComments}>Edit Review<i className="mdi mdi-star-outline ms-1"></i></button>
          )}
          {iAmNotGranted(perms.edit_notary_rating_comments) && !isNotaryRated(customActiveTab) &&
            <button type="button" className="btn btn-primary" onClick={formik.handleSubmit} disabled={!formik.isValid || formik.isSubmitting}>Add Review<i className="mdi mdi-star-outline ms-1"></i></button>}
        </div>
      </Modal>
    </>
  )
}

NotaryRating.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default NotaryRating
