import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

const ValueContainer = ({ children, getValue, ...props }) => {
  var values = getValue();
  var valueLabel = "";

  if (values.length > 0) {
    // instead of displaying selected items, we show just the number of selected items
    valueLabel += `${values.length} selected`;
  }

  // Keep standard placeholder and input from react-select
  const childsToRender = React.Children
    .toArray(children)
    .filter(child => ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0);

  return <components.ValueContainer {...props}>
    {!props.selectProps.inputValue && valueLabel}
    {childsToRender}
  </components.ValueContainer>
}

ValueContainer.propTypes = {
  children: PropTypes.any,
  getValue: PropTypes.func,
  selectProps: PropTypes.any,
}

const CustomSelect = props => {

  return <Select {...props} components={{ ValueContainer }} hideSelectedOptions={false} />
};

export default CustomSelect;

