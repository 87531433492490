import { takeEvery, put, call, all } from 'redux-saga/effects';

import {
  GET_ORDER_DT,

  GET_INACTIVE_ORDER_LIST,

  GET_ORDER,
  DELETE_ORDER,
  GET_ORDER_WITH_CUSTOMER_SIGNERS,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES,
  GET_FULL_ORDER,

  RESEND_CUSTOMER_NOTIFICATIONS,
  RELOAD_DEALER_SIGNERS,

  START_CUSTOMER_MEETING,
  START_NOTARY_MEETING,

  GET_ORDER_VID,

  GET_ORDER_MESSAGES,
  GET_CHAT_MEMBERS,

  GET_ORDER_ACTIVITY,

  GET_ORDER_INK_SIGN_DOCS,

  GET_ORDER_E_SIGN_DOCS,

  ASSIGN_ORDER_SCHEDULER,

  AWARD_NOTARY_BID,
  DECLINE_NOTARY_BID,
  NOTIFY_NOTARY_BID,

  START_ORDER_NOTARY_BID,

  GET_ORDER_NOTARY_BID_DT,

  GET_ORDER_NOTARY_DT,
  ADD_ORDER_NOTARY_BID,

  START_NOTARY_REQUEUE,

  UPDATE_ORDER_CUSTOMER,
  UPDATE_ORDER_VEHICLE,
  UPDATE_ORDER_CONTRACT,

  GET_ORDER_SUPPORTING_DOCS,
  GET_ORDER_PREVIEW_DOCS,
  SET_ORDER_IS_RURAL,
  GET_NOTARY_ORDER_DT,
} from './actionTypes';

import {
  getOrderDtOk,
  getOrderDtErr,

  getOrderListOk,
  getOrderListErr,

  getOrderOk,
  getOrderWithCustomerSignersOk,
  getOrderWithCustomerSignersAndNotariesOk,
  getFullOrderOk,
  getOrderErr,
  deleteOrderOk,
  deleteOrderErr,

  resendCustomerNotificationsOk,
  resendCustomerNotificationsErr,

  startCustomerMeetingOk,
  startCustomerMeetingErr,
  startNotaryMeetingOk,
  startNotaryMeetingErr,

  getOrderVidOk,
  getOrderVidErr,

  getOrderMessagesOk,
  getOrderMessagesErr,
  getChatMembersOk,
  getChatMembersErr,

  getOrderActivityOk,
  getOrderActivityErr,

  getOrderInkSignDocsOk,
  getOrderInkSignDocsErr,

  getOrderESignDocsOk,
  getOrderESignDocsErr,

  assignOrderSchedulerOk,
  assignOrderSchedulerErr,

  awardNotaryBidOk,
  awardNotaryBidErr,
  declineNotaryBidOk,
  declineNotaryBidErr,
  notifyNotaryBidOk,
  notifyNotaryBidErr,

  startOrderNotaryBidOk,
  startOrderNotaryBidErr,

  getOrderNotaryBidDtOk,
  getOrderNotaryBidDtErr,

  getOrderNotaryDtOk,
  getOrderNotaryDtErr,

  addOrderNotaryBidOk,
  addOrderNotaryBidErr,

  startNotaryRequeueOk,
  startNotaryRequeueErr,

  saveOrderOk,
  saveOrderErr,

  getOrderSupportingDocsOk,
  getOrderSupportingDocsErr,
  getOrderPreviewDocsOk,
  getOrderPreviewDocsErr,

  getNotaryOrderDtOk,
  getNotaryOrderDtErr,

} from './actions';

import {
  getOrderDt,
  getOrder,
  deleteOrder,
  getOrderSigners,
  getOrderVehicles,
  getOrderVid,
  getOrderMessages,
  getOrderActivity,
  getOrderNotaries,
  resendCustomerNotifications,
  getInkSignOrderDocs,
  getESignOrderDocs,
  getChatMembers,
  assignScheduler,
  startNotaryBid,
  getOrderNotaryBidDt,
  getOrderNotaryDt,
  awardNotaryBid,
  rejectNotaryBid,
  notifyNotaryBid,
  addOrderNotaryBid,
  startNotaryRequeue,
  createOrderMeeting,
  updateOrderCustomer,
  updateOrderVehicle,
  updateOrderContract,
  getSupportingOrderDocs,
  getFinanceOrderDocs,
  setIsRural,
  getNotaryOrdersDt,
} from 'helpers/backendHelper';

import OrderSigner from 'model/orderSigner';
import { getInactiveOrdersFilters } from 'helpers/utilHelper';

/********** DATATABLE **********/

function* onGetOrderDt({ payload: { params } }) {
  try {
    const { filters } = params;

    let { includeService, includeStatus, incAlert, vidStatuses, underReviewStatus, duplicateReviewStatus, ...rest } = filters;
    // parse arrays to strings
    const incService = includeService.join(",");
    const incStatus = includeStatus.join(",");
    const alert = incAlert.join(",");
    const vidStatus = vidStatuses.join(",");
    underReviewStatus = underReviewStatus.join(",");
    duplicateReviewStatus = duplicateReviewStatus.join(",");

    const response = yield call(getOrderDt, { ...params, filters: { incService, incStatus, alert, vidStatus, underReviewStatus, duplicateReviewStatus, ...rest } });
    yield put(getOrderDtOk(response));
  } catch (error) {
    yield put(getOrderDtErr(error));
  }
}

/********** LIST **********/

function* onGetInactiveOrders() {
  try {
    const response = yield call(getOrderDt, {
      pageSize: 10,
      page: 1,
      sortDir: 'desc',
      sortBy: 'id',
      filters: getInactiveOrdersFilters(),
    });

    yield put(getOrderListOk(response));
  } catch (error) {
    yield put(getOrderListErr(error));
  }
}

function* onGetNotaryOrdersDt({ payload: { id, params } }) {
  try {
    const { filters } = params;

    const response = yield call(getNotaryOrdersDt, id, { ...params, filters: { ...filters } });
    yield put(getNotaryOrderDtOk(response));
  } catch (error) {
    yield put(getNotaryOrderDtErr(error));
  }
}

/********** SINGLE **********/

function* onGetOrder({ payload: { id } }) {
  try {
    const response = yield call(getOrder, id);
    yield put(getOrderOk(response));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

function* onDeleteOrder({ payload: { id } }) {
  try {
    const response = yield call(deleteOrder, id);
    yield put(deleteOrderOk(response));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

function* onGetOrderWithCustomerSigners({ payload: { id } }) {
  try {
    const [orderResponse, signersResponse] = yield all([
      call(getOrder, id),
      call(getOrderSigners, id, OrderSigner.TYPE_CUSTOMER),
    ]);
    yield put(getOrderWithCustomerSignersOk(orderResponse, signersResponse));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

function* onGetOrderWithCustomerSignersAndNotaries({ payload: { id } }) {
  try {
    const [orderResponse, signersResponse, notariesResponse] = yield all([
      call(getOrder, id),
      call(getOrderSigners, id, OrderSigner.TYPE_CUSTOMER),
      call(getOrderNotaries, id),
    ]);
    yield put(getOrderWithCustomerSignersAndNotariesOk(orderResponse, signersResponse, notariesResponse));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

function* onGetFullOrder({ payload: { id } }) {
  try {
    const [orderResponse, signersResponse, vehiclesResponse, notariesResponse] = yield all([
      call(getOrder, id),
      call(getOrderSigners, id, OrderSigner.TYPE_CUSTOMER),
      call(getOrderVehicles, id),
      call(getOrderNotaries, id),
    ]);
    yield put(getFullOrderOk(orderResponse, signersResponse, vehiclesResponse, notariesResponse));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

function* onResendCustomerNotifications({ payload: { id } }) {
  try {
    yield call(resendCustomerNotifications, id);
    yield put(resendCustomerNotificationsOk());
  } catch (error) {
    yield put(resendCustomerNotificationsErr(error));
  }
}

function* onStartCustomerMeeting({ payload: { orderId } }) {
  try {
    const response = yield call(createOrderMeeting, orderId, "customer");
    yield put(startCustomerMeetingOk(response));
  } catch (error) {
    yield put(startCustomerMeetingErr(error));
  }
}

function* onStartNotaryMeeting({ payload: { orderId } }) {
  try {
    const response = yield call(createOrderMeeting, orderId, "notary");
    yield put(startNotaryMeetingOk(response));
  } catch (error) {
    yield put(startNotaryMeetingErr(error));
  }
}

/********** FORM **********/

function* onUpdateOrderCustomer({ payload: { data, id } }) {
  try {
    const response = yield call(updateOrderCustomer, data, id);
    yield put(saveOrderOk(response));
  } catch (error) {
    yield put(saveOrderErr(error));
  }
}

function* onUpdateOrderVehicle({ payload: { data, id } }) {
  try {
    const response = yield call(updateOrderVehicle, data, id);
    yield put(saveOrderOk(response));
  } catch (error) {
    yield put(saveOrderErr(error));
  }
}

function* onUpdateOrderContract({ payload: { data, id } }) {
  try {
    const response = yield call(updateOrderContract, data, id);
    yield put(saveOrderOk(response));
  } catch (error) {
    yield put(saveOrderErr(error));
  }
}

function* onSetIsRural({ payload: { id, isRural } }) {
  try {
    const response = yield call(setIsRural, id, isRural);
    yield put(saveOrderOk(response));
  } catch (error) {
    yield put(saveOrderErr(error));
  }
}

/********** VID **********/

function* onGetOrderVid({ payload: { id, signer } }) {
  try {
    const response = yield call(getOrderVid, id, signer);
    yield put(getOrderVidOk(response, signer));
  } catch (error) {
    yield put(getOrderVidErr(error, signer));
  }
}

/********** INK-SIGN DOCS **********/

function* onGetOrderInkSignDocs({ payload: { id } }) {
  try {
    const response = yield call(getInkSignOrderDocs, id);
    yield put(getOrderInkSignDocsOk(response));
  } catch (error) {
    yield put(getOrderInkSignDocsErr(error));
  }
}

/********** E-SIGN DOCS **********/

function* onGetOrderESignDocs({ payload: { id } }) {
  try {
    const response = yield call(getESignOrderDocs, id);
    yield put(getOrderESignDocsOk(response));
  } catch (error) {
    yield put(getOrderESignDocsErr(error));
  }
}

/********** SUPPORTING DOCS **********/

function* onGetOrderSupportingDocs({ payload: { id } }) {
  try {
    const response = yield call(getSupportingOrderDocs, id);
    yield put(getOrderSupportingDocsOk(response));
  } catch (error) {
    yield put(getOrderSupportingDocsErr(error));
  }
}

/********** PREVIEW DOCS **********/

function* onGetOrderPreviewDocs({ payload: { id } }) {
  try {
    const response = yield call(getFinanceOrderDocs, id);
    yield put(getOrderPreviewDocsOk(response));
  } catch (error) {
    yield put(getOrderPreviewDocsErr(error));
  }
}

/********** ACTIVITY LOG **********/

function* onGetOrderActivity({ payload: { id, limit, params } }) {
  try {
    const response = yield call(getOrderActivity, id, limit, params);
    yield put(getOrderActivityOk(response));
  } catch (error) {
    yield put(getOrderActivityErr(error));
  }
}

/********** MESSAGES **********/

function* onGetOrderMessages({ payload: { orderId, channelId, params } }) {
  try {
    const response = yield call(getOrderMessages, orderId, channelId, params);
    yield put(getOrderMessagesOk(response, channelId));
  } catch (error) {
    yield put(getOrderMessagesErr(error));
  }
}

function* onGetChatMembers({ payload: { orderId, channelId } }) {
  try {
    const response = yield call(getChatMembers, orderId, channelId);
    yield put(getChatMembersOk(response, channelId));
  } catch (error) {
    yield put(getChatMembersErr(error));
  }
}

/********** SCHEDULER **********/

function* onAssignOrderScheduler({ payload: { data, orderId } }) {
  try {
    const response = yield call(assignScheduler, data, orderId);
    yield put(assignOrderSchedulerOk(response));
  } catch (error) {
    yield put(assignOrderSchedulerErr(error));
  }
}

/********** NOTARY BID **********/

function* onAwardNotaryBid({ payload: { id } }) {
  try {
    const response = yield call(awardNotaryBid, id);
    yield put(awardNotaryBidOk(response));
  } catch (error) {
    yield put(awardNotaryBidErr(error));
  }
}

function* onDeclineNotaryBid({ payload: { id } }) {
  try {
    const response = yield call(rejectNotaryBid, id);
    yield put(declineNotaryBidOk(response));
  } catch (error) {
    yield put(declineNotaryBidErr(error));
  }
}

function* onNotifyNotaryBid({ payload: { id } }) {
  try {
    const response = yield call(notifyNotaryBid, id);
    yield put(notifyNotaryBidOk(response));
  } catch (error) {
    yield put(notifyNotaryBidErr(error));
  }
}

function* onStartOrderNotaryBid({ payload: { id } }) {
  try {
    const response = yield call(startNotaryBid, id);
    yield put(startOrderNotaryBidOk(response));
  } catch (error) {
    yield put(startOrderNotaryBidErr(error));
  }
}

function* onAddOrderNotaryBid({ payload: { orderId, notaryId } }) {
  try {
    const response = yield call(addOrderNotaryBid, orderId, notaryId);
    yield put(addOrderNotaryBidOk(response));
  } catch (error) {
    yield put(addOrderNotaryBidErr(error));
  }
}

/********** NOTARY BID DATATABLE **********/

function* onGetOrderNotaryBidDt({ payload: { id } }) {
  try {
    const response = yield call(getOrderNotaryBidDt, id);
    yield put(getOrderNotaryBidDtOk(response));
  } catch (error) {
    yield put(getOrderNotaryBidDtErr(error));
  }
}

function* onStartNotaryRequeue({ payload: { id, notaryId } }) {
  try {
    const response = yield call(startNotaryRequeue, id, notaryId);
    yield put(startNotaryRequeueOk(response));
  } catch (error) {
    yield put(startNotaryRequeueErr(error));
  }
}

/********** NOTARY DATATABLE **********/

function* onGetOrderNotaryDt({ payload: { id, params } }) {
  try {
    const response = yield call(getOrderNotaryDt, id, params);
    yield put(getOrderNotaryDtOk(response));
  } catch (error) {
    yield put(getOrderNotaryDtErr(error));
  }
}

function* orderSaga() {
  yield takeEvery(GET_ORDER_DT, onGetOrderDt);

  yield takeEvery(GET_INACTIVE_ORDER_LIST, onGetInactiveOrders);
  yield takeEvery(GET_NOTARY_ORDER_DT, onGetNotaryOrdersDt);

  yield takeEvery(GET_ORDER, onGetOrder);
  yield takeEvery(GET_ORDER_WITH_CUSTOMER_SIGNERS, onGetOrderWithCustomerSigners);
  yield takeEvery(GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES, onGetOrderWithCustomerSignersAndNotaries);
  yield takeEvery(GET_FULL_ORDER, onGetFullOrder);
  yield takeEvery(DELETE_ORDER, onDeleteOrder);

  yield takeEvery(UPDATE_ORDER_CUSTOMER, onUpdateOrderCustomer);
  yield takeEvery(UPDATE_ORDER_VEHICLE, onUpdateOrderVehicle);
  yield takeEvery(UPDATE_ORDER_CONTRACT, onUpdateOrderContract);
  yield takeEvery(SET_ORDER_IS_RURAL, onSetIsRural);

  yield takeEvery(RESEND_CUSTOMER_NOTIFICATIONS, onResendCustomerNotifications);

  yield takeEvery(START_CUSTOMER_MEETING, onStartCustomerMeeting);
  yield takeEvery(START_NOTARY_MEETING, onStartNotaryMeeting);

  yield takeEvery(GET_ORDER_VID, onGetOrderVid);

  yield takeEvery(GET_ORDER_INK_SIGN_DOCS, onGetOrderInkSignDocs);
  yield takeEvery(GET_ORDER_E_SIGN_DOCS, onGetOrderESignDocs);
  yield takeEvery(GET_ORDER_SUPPORTING_DOCS, onGetOrderSupportingDocs);
  yield takeEvery(GET_ORDER_PREVIEW_DOCS, onGetOrderPreviewDocs);

  yield takeEvery(GET_ORDER_ACTIVITY, onGetOrderActivity);

  yield takeEvery(GET_ORDER_MESSAGES, onGetOrderMessages);
  yield takeEvery(GET_CHAT_MEMBERS, onGetChatMembers);

  yield takeEvery(ASSIGN_ORDER_SCHEDULER, onAssignOrderScheduler);

  yield takeEvery(AWARD_NOTARY_BID, onAwardNotaryBid);
  yield takeEvery(DECLINE_NOTARY_BID, onDeclineNotaryBid);
  yield takeEvery(NOTIFY_NOTARY_BID, onNotifyNotaryBid);
  yield takeEvery(START_ORDER_NOTARY_BID, onStartOrderNotaryBid);

  yield takeEvery(ADD_ORDER_NOTARY_BID, onAddOrderNotaryBid);

  yield takeEvery(GET_ORDER_NOTARY_BID_DT, onGetOrderNotaryBidDt);

  yield takeEvery(GET_ORDER_NOTARY_DT, onGetOrderNotaryDt);

  yield takeEvery(START_NOTARY_REQUEUE, onStartNotaryRequeue);
}

export default orderSaga;