import { LocalException } from './errorHelper';
import { isObject, valueIsEmpty } from './utilHelper';

export const routes = {
  home: '/',

  list_leaderboard: '/dashboard/leaderboard',

  list_users: '/users',
  new_user: '/user',
  view_user: '/user/:id',
  delete_user: '/user/:id/delete',

  list_dealer_groups: '/dealer-groups',
  view_dealer_group: '/dealer-group/:id',
  new_dealer_group: '/dealer-group',
  new_dealer_group_region: '/dealer-group/:id/region',
  new_dealer_group_manager: '/dealer-group/:id/manager',

  view_dealer_region: '/dealer-region/:id',
  new_dealer_region_manager: '/dealer-region/:id/manager',

  list_dealer_stores: '/dealer-stores',
  view_dealer_store: '/dealer-store/:id',
  new_dealer_store: '/dealer-store',
  new_dealer_store_user: '/dealer-store/:id/user',

  new_dealer_store_signer: '/dealer-store/:id/signer',

  view_dealer_store_signer: '/dealer-store-signer/:id',

  list_notifications: '/notifications',
  view_notification: '/notification/:id',

  list_orders: '/orders',
  list_orders_remote: '/orders-remote',
  list_orders_in_store: '/orders-in-store',
  view_order: '/order/:id',
  view_order_vid: '/order/:id/vid',
  view_order_e_sign: '/order/:id/e-sign',
  view_order_ink_sign: '/order/:id/ink-sign',
  view_order_finance: '/order/:id/finance',
  view_order_supporting: '/order/:id/supporting',
  view_order_messages: '/order/:id/messages/:channel',
  view_order_channel: '/order/:id/channels/:channel/',
  view_order_activity: '/order/:id/activity',
  view_order_scheduler: '/order/:id/scheduler',
  view_order_notary: '/order/:id/notary',
  view_order_notary_manual_search: '/order/:id/notary/manual-search',
  view_order_notary_information: '/order/:id/notary-information',
  view_order_bidding_logs: '/order/:id/bidding/logs',
  list_pending_review_orders: '/pending_review_orders',

  view_quickbooks: '/quickbooks',

  view_accounting_edit: '/order/:id/accounting-edit/:customValue',

  view_seal: '/order/:id/seal',

  list_payment_plans: '/payment-plans',
  view_payment_plan: '/payment-plan/:id',
  new_payment_plan: '/payment-plan',
  new_payment_plan_fee: '/payment-plan/:id/custom-fees',
  view_payment_plan_fee: '/custom-fee/:id',

  list_messages: '/messages',

  list_support_cases: '/support-cases',
  view_support_case: '/support-case/:id',

  list_notaries: '/notaries',
  view_notary: '/notary/:id',
  new_notary: '/notary',

  list_additional_fees: '/additional-fees',
  view_additional_fee: '/additional-fee/:id',

  view_scheduler_performance_report: '/scheduler-performance-report',
  view_group_usage_report: '/group-usage-report',
  view_dealer_sales_report: '/dealer-sales-report',
  view_new_dealers_report: '/new-dealers-report',

  list_payments: '/payments',
  view_payment: '/payment/:id',

  list_dealer_statements: '/dealer-statements',
  view_dealer_statement: '/dealer-statement/:id',
  list_notary_statements: '/notary-statements',
  view_notary_statement: '/notary-statement/:id',
  view_biddings: '/biddings',

  list_cron_jobs: '/cron-jobs',
  view_cron_job: '/cron-job/:id',
  list_error_codes: '/err-codes',
  list_sys_processes: '/sys-processes',
  view_sys_process: '/sys-process/:id',
  list_route_one_logs: '/route-one-logs',
  view_route_one_log: '/route-one-log/:id',

  list_help: '/help',

  route_one: '/route-one',
};

export const route = (route, params, query) => {
  if (!Object.values(routes).includes(route)) {
    throw new LocalException(`Unknown route '${route}'`);
  }
  if (!valueIsEmpty(params)) {
    if (Array.isArray(params)) {
      params.forEach(param => {
        // handle optional params
        if (valueIsEmpty(param)) {
          route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
        } else {
          route = route.replace(new RegExp(':([a-zA-Z]+)'), param);
        }
      });
    } else if (isObject(params)) {
      for (let key in params) {
        // handle optional params
        if (valueIsEmpty(params[key])) {
          route = route.replace(new RegExp(`/:${key}`), '');
        } else {
          route = route.replace(new RegExp(`:${key}`), params[key]);
        }
      }
    } else {
      // handle optional params
      if (valueIsEmpty(params)) {
        route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
      } else {
        route = route.replace(new RegExp(':([a-zA-Z]+)'), params);
      }
    }
  }
  if (query) {
    if (isObject(query)) {
      let parts = [];
      for (let key in query) {
        if (Array.isArray(query[key])) {
          query[key].forEach(value => {
            parts.push(`${key}[]=${value}`);
          });
        } else if (isObject(query[key])) {
          for (let key2 in query[key]) {
            parts.push(`${key}[${key2}]=${query[key][key2]}`);
          }
        } else {
          parts.push(`${key}=${query[key]}`);
        }
      }
      route += `?${parts.join('&')}`;
    } else {
      route += `?${query}`;
    }
  }
  return route;
};