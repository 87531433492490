import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from "reactstrap";
import FormRouteOneEdit from '../Form/RouteOne/Edit';
import ViewRouteOne from '../View/RouteOne';

const SectionRouteOne = props => {

  const { isLoadBusy, id, dealerStore, refreshHandler } = props;

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshHandler();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <Card>
      <CardBody className="pt-3">
        <FormRouteOneEdit id={id} defaultValues={dealerStore} finishedHandler={toggleEditMode} />
      </CardBody>
    </Card>}
    {!isEditModeActive && <ViewRouteOne dealerStore={dealerStore} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

SectionRouteOne.propTypes = {
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  dealerStore: PropTypes.object,
  refreshHandler: PropTypes.func,
};

export default SectionRouteOne;