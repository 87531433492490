import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Order from 'model/order';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';
import ExpanderToggle from '../ExpanderToggle';
import ExpanderDetails from '../ExpanderDetails';

const OrderDuplicateReviewStatusChanged = props => {

  const { log } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  return <React.Fragment>
    <div className="d-flex">
      <AuthorAvatar log={log} />
      <div className="flex-grow-1">
        {log.newValues.duplicateReviewStatus === Order.REVIEW_STATUS_ACTIVE && <React.Fragment>
          <AuthorName log={log} /> marked order for <b>duplicate review</b>.
        </React.Fragment>}
        {log.newValues.duplicateReviewStatus === null && <React.Fragment>
          <AuthorName log={log} /> released the <b>duplicate review</b> hold from the order.
        </React.Fragment>}
        {[Order.REVIEW_STATUS_FRAUD, Order.REVIEW_STATUS_HIGH_RISK].includes(log.newValues.duplicateReviewStatus) && <React.Fragment>
          <AuthorName log={log} /> changed order duplicate review status to <b>{Order.getReviewStatusName(log.newValues.duplicateReviewStatus)}</b>.
        </React.Fragment>}
        <p className="mb-0">
          <EventTime log={log} />
          {!!log.newValues.duplicateReviewReason && <ExpanderToggle {...props} {...{ isExpanded, setIsExpanded }} />}
        </p>
      </div>
    </div>
    {!!log.newValues.duplicateReviewReason && <ExpanderDetails {...props} {...{ isExpanded }}>
      <p className="m-0 ms-2">{log.newValues.duplicateReviewReason}</p>
    </ExpanderDetails>}
  </React.Fragment>
}

OrderDuplicateReviewStatusChanged.propTypes = {
  log: PropTypes.object,
}

export default OrderDuplicateReviewStatusChanged;