import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback, CardTitle } from "reactstrap";
import { nullsToEmptyStrings, showBriefError, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import { updateDealerStoreR1Id } from "helpers/backendHelper";

const FormRouteOneEdit = props => {

  const { defaultValues, id, finishedHandler } = props;

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    r1Id: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      r1Id: Yup.string().trim().required('Field is required').matches(/^[a-zA-Z0-9]{5}$/, 'Invalid Route One ID'),
    }),
    onSubmit: values => saveDealerStoreR1Id(values, id),
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveDealerStoreR1Id = (values, id) => {
    setIsSaveInProgress(true);
    updateDealerStoreR1Id(values, id)
      .then(_response => {
        showSuccess(`Store "${values.name}" has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save store');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <CardTitle>Route One</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">RouteOne Dealer ID</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. AX9WB" name="r1Id" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.r1Id} invalid={!!formik.errors.r1Id} />
              {!!formik.errors.r1Id && <FormFeedback type="invalid">{formik.errors.r1Id}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

FormRouteOneEdit.propTypes = {
  defaultValues: PropTypes.object,
  id: PropTypes.number,
  finishedHandler: PropTypes.func,
};

export default FormRouteOneEdit;