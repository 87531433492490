import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardHeader, CardBody, Input, Label, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import { doOrderActivityCleanup, doOrderSingleCleanup, getOrderActivity, getOrderWithCustomerSigners } from "store/actions";
import MetaTitle from "components/Shared/MetaTitle";
import AccessDenied from "pages/Error/AccessDenied";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import { route, routes } from "helpers/routeHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import ActivityEntry from "components/Shared/ActivityEntry";
import Select from "react-select";
import { dictionaryToSelectOptions } from 'helpers/utilHelper';
import ActivityLog from 'model/activityLog';
import CustomSelect from 'components/Shared/CustomSelect';
import classnames from "classnames";

const ViewActivity = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);
  const { logs, logsError, isLoadInProgress: isLogLoadInProgress } = useSelector(state => state.Order.Activity);
  const [showSystemLogs, setShowSystemLogs] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [includeEvents, setIncludeEvents] = useState([]);
  const [excludeEvents, setExcludeEvents] = useState([]);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data
    refreshOrder();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  useEffect(() => {
    if (!order) {
      return;
    }
    refreshLogs();
    return () => {
      dispatch(doOrderActivityCleanup());
    }
  }, [order]);

  useEffect(() => {
    if (!order) {
      return;
    }
    refreshLogs();
  }, [showSystemLogs, entriesPerPage, includeEvents, excludeEvents]);

  /********** OTHER **********/

  const refreshOrder = () => dispatch(getOrderWithCustomerSigners(id));

  const refreshLogs = () => dispatch(getOrderActivity(order.id, entriesPerPage, {
    filters: {
      excludeSysLogs: !showSystemLogs,
      includeEvents,
      excludeEvents,
    }
  }));

  const getPaginationOptions = () => [{
    label: 100,
    value: 100,
  }, {
    label: 200,
    value: 200,
  }, {
    label: 500,
    value: 500,
  }, {
    label: 'All',
    value: 0,
  }];

  const getEventOptions = () => dictionaryToSelectOptions(ActivityLog.getEventMap());

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <div className="d-flex justify-content-between mt-2 mb-3">
                    <div className="card-title">Activity Log</div>
                    <div className="activity-log-filters">
                      <div className="d-inline-block ms-3">
                        <Label className="mb-0 me-2">Show system logs</Label>
                        <div className="form-check form-switch form-switch-lg d-inline-block mb-0">
                          <Input type="checkbox" className="form-check-input" id="activeSwitch" name="isActive" onChange={() => { setShowSystemLogs(!showSystemLogs) }} defaultChecked={showSystemLogs} />
                          <Label className="form-check-label" htmlFor="activeSwitch" />
                        </div>
                      </div>
                      <div className="d-inline-block ms-3">
                        <Label className="mb-0 me-2">Include events</Label>
                        <CustomSelect
                          styles={{
                            container: (baseStyles, state) => ({
                              ...baseStyles,
                              width: '250px'
                            })
                          }}
                          className="d-inline-block"
                          classNamePrefix="select2-selection"
                          options={getEventOptions()}
                          getOptionLabel={option => option.label}
                          getOptionValue={option => option.value}
                          onChange={selected => setIncludeEvents(selected.map(s => s.value))}
                          value={getEventOptions().filter(option => includeEvents.includes(option.value))}
                          isMulti />
                      </div>
                      <div className="d-inline-block ms-3">
                        <Label className="mb-0 me-2">Exclude events</Label>
                        <CustomSelect
                          styles={{
                            container: (baseStyles, state) => ({
                              ...baseStyles,
                              width: '250px'
                            })
                          }}
                          className="d-inline-block"
                          classNamePrefix="select2-selection"
                          options={getEventOptions()}
                          getOptionLabel={option => option.label}
                          getOptionValue={option => option.value}
                          onChange={selected => setExcludeEvents(selected.map(s => s.value))}
                          value={getEventOptions().filter(option => excludeEvents.includes(option.value))}
                          isMulti />
                      </div>
                      <div className="d-inline-block ms-3">
                        <Label className="mb-0 me-2">Show entries</Label>
                        <Select
                          styles={{
                            container: (baseStyles, state) => ({
                              ...baseStyles,
                              width: '100px'
                            })
                          }}
                          className="d-inline-block"
                          classNamePrefix="select2-selection"
                          options={getPaginationOptions()}
                          getOptionLabel={option => option.label}
                          getOptionValue={option => option.value}
                          onChange={selected => setEntriesPerPage(selected.value)}
                          value={getPaginationOptions().filter(option => option.value == entriesPerPage)} />
                      </div>
                      <Button type="button" color="primary" className="mt-n1 ms-3 d-inline-block align-center" onClick={refreshLogs} disabled={isLogLoadInProgress}>
                        <i className={classnames("mdi mdi-reload me-1", { 'mdi-spin': isLogLoadInProgress })} />Refresh
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pb-5">
                  {isLogLoadInProgress && <SpinnerChase />}
                  {!!logs.length && <ul className="verti-timeline list-unstyled">
                    {logs.map(log => <ActivityEntry key={log._id} log={log} />)}
                  </ul>}
                  {logsError && <Error error={logsError} title404="Order not found" />}
                  {!logs.length && !isLogLoadInProgress && !logsError && <p>No entries found</p>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: `${order.signers[0].fullName}`,
  url: route(routes.view_order, order.id),
}, {
  title: 'Activity',
}];

export default ViewActivity;