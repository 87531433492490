import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormFeedback, Input, Label, Row, Alert } from "reactstrap";
import DatePicker from "components/Shared/DatePicker";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import classnames from "classnames";
import { getOrderQbInfo, saveOrderQbInfo } from 'helpers/backendHelper';
import { nullsToEmptyStrings, showError, showSuccess } from 'helpers/utilHelper';
import SpinnerChase from 'components/Shared/SpinnerChase';

const FormQuickbooksEdit = ({ order, refreshOrder }) => {

  let { id } = useParams();
  id = parseInt(id);

  /********** STATE **********/

  const [data, setData] = useState(null);
  const [dataError, setDataError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  useEffect(() => {
    refreshQbData();
  }, [order.id]);

  const refreshQbData = () => {
    setIsLoadInProgress(true);
    setDataError(null);
    getOrderQbInfo(order.id)
      .then(response => {
        setData(response);
      })
      .catch(ex => {
        setDataError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }

  const saveQbData = values => {
    setIsSaveInProgress(true);
    saveOrderQbInfo(order.id, values)
      .then(response => {
        showSuccess('Data has been saved');
        refreshOrder();
        refreshQbData();
      })
      .catch(ex => {
        showError('Unable to save data');
      })
      .finally(() => {
        setIsSaveInProgress(false);
        formik.setSubmitting(false);
      });
  }

  /********** OTHER **********/

  // router hook that helps redirect
  const navigate = useNavigate();

  /********** FORM CONFIG **********/

  const formInitialValues = {
    dealerCreatedTs: undefined,
    dealerInvoiceId: '',
    notaryCreatedTs: undefined,
    notaryPaymentAmount: '',
    notaryPaymentRefId: '',
    notaryBillId: '',
    notaryPaymentCreatedTs: undefined,
    notaryPaymentSent: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      dealerCreatedTs: data?.hasDealerInfo ? Yup.string().trim().required('Field is required').nullable() : Yup.string().trim().nullable(),
      dealerInvoiceId: data?.hasDealerInfo ? Yup.string().trim().required('Field is required') : Yup.string().trim().nullable(),
      notaryCreatedTs: data?.hasNotaryInfo ? Yup.string().trim().required('Field is required').nullable() : Yup.string().trim().nullable(),
      notaryPaymentAmount: data?.hasNotaryInfo ? Yup.string().trim().required('Field is required') : Yup.string().trim(),
      notaryPaymentRefId: data?.hasNotaryInfo ? Yup.string().trim().required('Field is required') : Yup.string().trim(),
      notaryBillId: data?.hasNotaryInfo ? Yup.string().trim().required('Field is required') : Yup.string().trim(),
      notaryPaymentCreatedTs: data?.hasNotaryInfo ? Yup.string().trim().required('Field is required').nullable() : Yup.string().trim().nullable(),
      notaryPaymentSent: Yup.string().trim(),
    }),
    onSubmit: values => {
      saveQbData(values);
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    formik.setValues(nullsToEmptyStrings({
      dealerCreatedTs: data.dealerCreatedTs ?? undefined,
      dealerInvoiceId: data.dealerInvoiceId,
      notaryCreatedTs: data.notaryCreatedTs ?? undefined,
      notaryBillId: data.notaryBillId,
      notaryPaymentAmount: data.notaryPaymentAmount,
      notaryPaymentCreatedTs: data.notaryPaymentCreatedTs ?? undefined,
      notaryPaymentRefId: data.notaryPaymentRefId,
    }));
  }, [data]);

  /********** EVENT HANDLERS **********/

  //focus event handler used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <React.Fragment>
    {!!dataError && <Alert color="danger" className="fade show text-center mb-2">
      <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load data
    </Alert>}
    {!!data && <Form>
      <Row>
        <Col sm={6}>
          {data.hasDealerInfo && <div className="mb-2">
            <Label className="col-form-label">Invoiced Dealership In Qb</Label>
            <DatePicker
              required
              name="dealerCreatedTs"
              onChange={selected => formik.setFieldValue("dealerCreatedTs", selected)}
              onFocus={onFieldFocused}
              value={formik.values.dealerCreatedTs}
              invalid={!!formik.errors.dealerCreatedTs}
              format="d M, Y"
              disabled={!!data.dealerCreatedTs} />
            {!!formik.errors.dealerCreatedTs && <FormFeedback type="invalid">{formik.errors.dealerCreatedTs}</FormFeedback>}
          </div>}

          {data.hasNotaryInfo && <React.Fragment>
            <div className="mb-2">
              <Label className="col-form-label">Notary Bill Entered In Qb</Label>
              <DatePicker
                required
                name="notaryCreatedTs"
                onChange={selected => formik.setFieldValue("notaryCreatedTs", selected)}
                onFocus={onFieldFocused}
                value={formik.values.notaryCreatedTs}
                invalid={!!formik.errors.notaryCreatedTs}
                format="d M, Y"
                disabled={!!data.notaryCreatedTs} />
              {!!formik.errors.notaryCreatedTs && <FormFeedback type="invalid">{formik.errors.notaryCreatedTs}</FormFeedback>}
            </div>

            <div className="mb-2">
              <Label className="col-form-label">Notary Quickbooks - Amount</Label>
              <Input type="text" className="form-control" placeholder="Enter Notary Fee" name="notaryPaymentAmount" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.notaryPaymentAmount} invalid={!!formik.errors.notaryPaymentAmount} disabled={!!data.notaryPaymentAmount} />
              {!!formik.errors.notaryPaymentAmount && <FormFeedback type="invalid">{formik.errors.notaryPaymentAmount}</FormFeedback>}
            </div>

            <div className="mb-2">
              <Label className="col-form-label">Notary Paid - Check#</Label>
              <Input type="text" className="form-control" placeholder="Enter Notary Fee" name="notaryPaymentRefId" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.notaryPaymentRefId} invalid={!!formik.errors.notaryPaymentRefId} disabled={!!data.notaryPaymentRefId} />
              {!!formik.errors.notaryPaymentRefId && <FormFeedback type="invalid">{formik.errors.notaryPaymentRefId}</FormFeedback>}
            </div>
          </React.Fragment>}
        </Col>

        <Col sm={6}>
          {data.hasDealerInfo && <div className="mb-2">
            <Label className="col-form-label">Quickbooks Dealer Invoice</Label>
            <Input type="text" className="form-control" placeholder="Enter Notary Fee" name="dealerInvoiceId" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.dealerInvoiceId} invalid={!!formik.errors.dealerInvoiceId} disabled={!!data.dealerInvoiceId} />
            {!!formik.errors.dealerInvoiceId && <FormFeedback type="invalid">{formik.errors.dealerInvoiceId}</FormFeedback>}
          </div>}

          {data.hasNotaryInfo && <React.Fragment>
            <div className="mb-2">
              <Label className="col-form-label">Quickbooks Notary Bill</Label>
              <Input type="text" className="form-control" placeholder="Enter Notary Fee" name="notaryBillId" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.notaryBillId} invalid={!!formik.errors.notaryBillId} disabled={!!data.notaryBillId} />
              {!!formik.errors.notaryBillId && <FormFeedback type="invalid">{formik.errors.notaryBillId}</FormFeedback>}
            </div>

            <div className="mb-2">
              <Label className="col-form-label">Notary Paid - Date</Label>
              <DatePicker
                required
                name="notaryPaymentCreatedTs"
                onChange={selected => formik.setFieldValue("notaryPaymentCreatedTs", selected)}
                onFocus={onFieldFocused}
                value={formik.values.notaryPaymentCreatedTs}
                invalid={!!formik.errors.notaryPaymentCreatedTs}
                format="d M, Y"
                disabled={!!data.notaryPaymentCreatedTs} />
              {!!formik.errors.notaryPaymentCreatedTs && <FormFeedback type="invalid">{formik.errors.notaryPaymentCreatedTs}</FormFeedback>}
            </div>

            <div className="mb-2">
              <Label className="col-form-label">Notary payment has been sent</Label>
              <Input type="text" className="form-control" placeholder="Enter Notary Fee" name="notaryPaymentSent" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.notaryPaymentSent} invalid={!!formik.errors.notaryPaymentSent} />
              {!!formik.errors.notaryPaymentSent && <FormFeedback type="invalid">{formik.errors.notaryPaymentSent}</FormFeedback>}
            </div>
          </React.Fragment>}
        </Col>
      </Row>

      <Col xs="auto mt-4">
        <div className="text-end">
          <Button type="button" color="secondary" className="ms-2 mb-2" onClick={() => navigate(route(routes.view_order, id))}>
            Cancel
          </Button>
          <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
            Save
          </Button>
        </div>
      </Col>
    </Form>}
    {isLoadInProgress && <SpinnerChase className="sm" />}
  </React.Fragment>
}

FormQuickbooksEdit.propTypes = {
  order: PropTypes.object,
  refreshOrder: PropTypes.func,
};

export default FormQuickbooksEdit;