import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { route, routes } from 'helpers/routeHelper';
import { getLogAuthorFullName } from 'helpers/utilHelper';
import UserRole from 'model/userRole';

const AuthorName = props => {

  const { log } = props;

  const hasLink = !!log.author?.id && !UserRole.isSignerType(log.author?.userRoleId);

  return <React.Fragment>
    {hasLink && <Link to={route(routes.view_user, log.author.id)}><b>{getLogAuthorFullName(log.author)}</b></Link>}
    {!hasLink && <b>{getLogAuthorFullName(log.author)}</b>}
  </React.Fragment>
}

AuthorName.propTypes = {
  log: PropTypes.object,
}

export default AuthorName;