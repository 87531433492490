import React from "react";
import PropTypes from 'prop-types';

const AccordionRow = ({title, description}) => {
  return (<div className="mb-3">
    <h6 className="step-title mb-3">{title}</h6>
    <div className="step-description">{description}</div>
  </div>)
}

AccordionRow.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

export default AccordionRow;