import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, useParams, } from "react-router-dom"
import { Card, CardBody, Row, Col, Alert, Button, UncontrolledTooltip } from "reactstrap"
import { getMiles, getSharedTableOptions, showError, showSuccess, showWarning } from "helpers/utilHelper"
import {
  awardNotaryBid,
  declineNotaryBid,
  notifyNotaryBid,
  doOrderNotaryBidDtCleanup,
  startNotaryRequeue,
  getOrderNotaryBidDt,
  doNotaryBidCleanup
} from "store/actions";
import { route, routes } from "helpers/routeHelper";
import Confirmation from "components/Shared/Confirmation";
import { AccessDeniedException, CANNOT_AWARD_SAME_NOTARY_TO_ORDER, ServerErrorException, UNABLE_SEND_SCHEDULER_ASSIGNED_TO_ORDER_NOTIF } from "helpers/errorHelper";
import classnames from "classnames";
import NotaryBid from "model/notaryBid";
import CountdownTimer from "components/Shared/CountdownTimer";
import config from "config";
import RequeueButton from "components/Shared/RequeueButton";
import assignNotaryIcon from "assets/images/assign-notary-icon.svg";
import notaryPendingIcon from "assets/images/notary-pending-icon.svg";
import rejectedByNotaryIcon from "assets/images/rejected-by-notary-icon.svg";
import notaryAwardedIcon from "assets/images/notary-awarded-icon.svg";
import senIcon from "assets/images/icon-sen.svg";
import Notary from "model/notary";
import moment from "moment-timezone";
import { refreshBiddingNotifications } from "helpers/backendHelper";

const DataTableNotaryBids = () => {

  let { id } = useParams();
  id = parseInt(id);

  const dispatch = useDispatch();

  /********** STATE **********/

  // Keep current batch into ref, we don't need to render the component on change
  const currentBatch = useRef();

  const { order } = useSelector(state => state.Order.Single);
  const { notaryBids: rows, notaryBidsError: rowsError, isLoadInProgress } = useSelector(state => state.Order.NotaryBidDt);
  const { awarded, awardError, declined, declineError } = useSelector(state => state.Order.NotaryBid);

  const [bidToAward, setBidToAward] = useState(null);
  const [bidToDecline, setBidToDecline] = useState(null);
  const [bidToNotify, setBidToNotify] = useState(null);
  const [isAwardConfirmationVisible, setIsAwardConfirmationVisible] = useState(false);
  const [isDeclineConfirmationVisible, setIsDeclineConfirmationVisible] = useState(false);
  const [isNotifyConfirmationVisible, setIsNotifyConfirmationVisible] = useState(false);

  const isNotarySearchExhausted = order.isNotarySearchExhausted ?
    'No matching notaries found.' :
    <div id='search-in-progress'>
      <span>No notaries to display</span>
    </div>;

  /********** EFFECTS **********/
  // runs once on component mount
  useEffect(() => {
    refreshBiddingAndNotifications();
    // runs on component unmount
    return () => {
      dispatch(doOrderNotaryBidDtCleanup());
      dispatch(doNotaryBidCleanup());
    }
  }, []);

  useEffect(() => {
    if (awarded === true) {
      showSuccess("Notary has been assigned");
    } else if (awarded === false) {
      if (awardError instanceof ServerErrorException) {
        if (awardError.code == UNABLE_SEND_SCHEDULER_ASSIGNED_TO_ORDER_NOTIF) {
          // user has been assigned but the notifications could not be sent (at least some of them)
          showWarning("Unable to send notifications");
          return;
        }
      }
      if (awardError instanceof AccessDeniedException) {
        if (awardError.code == CANNOT_AWARD_SAME_NOTARY_TO_ORDER) {
          // this notary has already been assigned to this order
          showError("Cannot assign same notary twice");
          return;
        }
      }
      showError("Unable to assign notary");
      setBidToAward(null);
    }
  }, [awarded]);

  useEffect(() => {
    if (declined === true) {
      showSuccess("Notary has been declined");
    } else if (declined === false) {
      if (declineError instanceof ServerErrorException) {
        if (declineError.code == UNABLE_SEND_SCHEDULER_ASSIGNED_TO_ORDER_NOTIF) {
          // user has been assigned but the notifications could not be sent (at least some of them)
          showWarning("Unable to send notifications");
          return;
        }
      }
      showError("Unable to decline notary");
      setBidToAward(null);
    }
  }, [declined]);

  /********** EVENT HANDLERS **********/

  const confirmAwardBid = bid => {
    setBidToAward(bid);
    setIsAwardConfirmationVisible(true);
  }

  const confirmDeclineBid = bid => {
    setBidToDecline(bid);
    setIsDeclineConfirmationVisible(true);
  }

  const confirmNotifyBid = bid => {
    setBidToNotify(bid);
    setIsNotifyConfirmationVisible(true);
  }

  const awardBid = () => {
    setIsAwardConfirmationVisible(false);
    dispatch(awardNotaryBid(bidToAward.id));
  }

  const declineBid = () => {
    setIsDeclineConfirmationVisible(false);
    dispatch(declineNotaryBid(bidToDecline.id));
  }

  const notifyBid = () => {
    setIsNotifyConfirmationVisible(false);
    dispatch(notifyNotaryBid(bidToNotify.id));
  }

  const requeueBid = user => {
    dispatch(startNotaryRequeue(id, user.notaryId));
  }

  const rowClasses = (row) => {
    let cssClass = "notary-selected-highlight-row";

    // Is last from batch (spacing between rows)
    if (row.isLastFromBatch) {
      cssClass = cssClass + " row-border-spacing"
    }
    if (row.status === NotaryBid.STATUS_SELECTED) {
      cssClass = cssClass + " notary-selected-highlight-row"
    }
    if (row.status === NotaryBid.STATUS_NOTIFIED) {
      cssClass = cssClass + " notary-bid-highlight-row "
    }
    if (row.status === NotaryBid.STATUS_ACCEPTED_BY_NOTARY || row.status === NotaryBid.STATUS_AWARDED_BY_SCHEDULER) {
      cssClass = cssClass + " accepted-awarded-bid"
    }
    if (row.status === NotaryBid.STATUS_DECLINED_BY_NOTARY || row.status === NotaryBid.STATUS_REJECTED_BY_SCHEDULER) {
      cssClass = cssClass + " notary-declined-highlight-row"
    }
    // Flicker waiting for next notification
    if (row.batchNum === currentBatch.current) {
      cssClass = cssClass + " until-next-notification"
    }
    // If timer row no class
    if (!!row.timer) {
      return "";
    }

    return cssClass;
  };

  // Refresh bidding and notifications
  const refreshBiddingAndNotifications = values => {
    refreshBiddingNotifications(order.id)
      .then(_response => {
        refreshNotaryBids();
      })
      .catch(ex => {
        showError('Unable to refresh bidding');
      })
  }

  /********** OTHER **********/

  const refreshNotaryBids = () => {
    // Setting the current batch 0 if we refresh the list
    currentBatch.current = 0;
    dispatch(getOrderNotaryBidDt(order.id));
  }

  const formatRows = (rows) => {
    if (rows.length > 0) {
      // Setting the sorted rows
      let sortedRows = [...rows];
      // express counter
      let numberOfExpressNotaries = 0;
      // mav counter
      let numberOfMavNotaries = 0;

      // Assign isLastFromBatch to each notary bid
      sortedRows.forEach((row, idx) => {
        // SEN
        if (row.notaryType === Notary.TYPE_SUPER_EXPRESS && !!row.batchNum) {
          row.isLastFromBatch = true;
        }
        // EXPRESS
        if (row.notaryType === Notary.TYPE_EXPRESS && !!row.batchNum) {
          numberOfExpressNotaries += 1;

          // Check if the current EXPRESS notary is the last in its batch
          const isNextDifferentTypeOrBatch =
            !sortedRows[idx + 1] || // No more rows after this one
            sortedRows[idx + 1].notaryType !== Notary.TYPE_EXPRESS; // Next row is a different type

          // Mark as last in batch if it’s the third EXPRESS in this group or if it’s followed by a different type/batch
          row.isLastFromBatch = (numberOfExpressNotaries % 3 === 0) || isNextDifferentTypeOrBatch;
        }
        // MAVERICK
        if (row.notaryType === Notary.TYPE_MAVERICK && !!row.batchNum) {
          numberOfMavNotaries += 1;

          // Check if the current MAV notary is the last in its batch
          const isNextDifferentTypeOrBatch =
            !sortedRows[idx + 1] || // No more rows after this one
            sortedRows[idx + 1].notaryType !== Notary.TYPE_MAVERICK; // Next row is a different type

          // Mark as last in batch if it’s the third MAV in this group or if it’s followed by a different type/batch
          row.isLastFromBatch = (numberOfMavNotaries % 6 === 0) || isNextDifferentTypeOrBatch;
        }
      })

      // Get Rows with batches (the manually added notaries does not have a batch)
      const rowsWithBatches = sortedRows.filter(row => !!row.batchNum);
      // Getting the last batch so we don't shown the last timer
      const lastBatch = !!rowsWithBatches.length ? rowsWithBatches[rowsWithBatches.length - 1].batchNum : 0;
      // Adding the timer if needed
      sortedRows.forEach((row, idx) => {
        // Get all the bids in the current batch
        const bidsInCurrentBatch = sortedRows.filter(bid => bid.batchNum === row.batchNum);
        // Get the manual notified ones
        const manualNotifiedInCurrentBatch = bidsInCurrentBatch.filter(bid => bid.isManuallyNotified);
        // Check if all are notified from the current batch
        // If all are notified we need to skip the batch
        const allAreManualNotified = bidsInCurrentBatch.length === manualNotifiedInCurrentBatch.length;
        // Check if all are declined from the current batch
        // If all are declined we need to skip the batch
        const allAreDeclined = bidsInCurrentBatch.filter(bid => bid.status === NotaryBid.STATUS_REJECTED_BY_SCHEDULER || bid.status === NotaryBid.STATUS_DECLINED_BY_NOTARY).length === bidsInCurrentBatch.length;
        // Check if all are declined OR manual notified
        const allAreDeclinedOrManualNotified = (manualNotifiedInCurrentBatch.length + bidsInCurrentBatch.filter(bid => bid.status === NotaryBid.STATUS_REJECTED_BY_SCHEDULER || bid.status === NotaryBid.STATUS_DECLINED_BY_NOTARY).length) === bidsInCurrentBatch.length;
        // Check if some are manual notified
        const someAreManualNotified = manualNotifiedInCurrentBatch.length > 0;
        // Check if some are declined
        const someAreDecliend = bidsInCurrentBatch.some(bid => !bid.notifiedTs);

        let timeUntilNextNotification = 0;
        // If we have some manual notified (past notifiyTs) or declined by scheduler (notifiyTs = null)
        // We need to get the highest notifiedTs - basically the ones that were notified in the current batch
        // This helps us to achieve the current timer
        // If no manual notified or no declined we need to go through the normal flow
        if (someAreManualNotified || someAreDecliend) {
          const notifiedTs = Math.max(...bidsInCurrentBatch.map(bid => { return !bid.isManuallyNotified ? bid.notifiedTs : 0 }));
          const notaryNextNotification = +config.NOTARY_BID_ITERATION_COOLDOWN;
          timeUntilNextNotification = (notifiedTs + notaryNextNotification);
        } else {
          const notaryNextNotification = +config.NOTARY_BID_ITERATION_COOLDOWN;
          timeUntilNextNotification = (row.notifiedTs + notaryNextNotification);
        }

        // We need to check if bidding is exhausted because we don't want to show the timer
        // after the bidding is complete and the user re-notifies someone
        if (timeUntilNextNotification > moment().unix() && !order.isNotarySearchExhausted) {
          // Setting the current batch so we can show the flickering and use it for the rest of logic else it will be 0
          if (!row.isManuallyNotified && !!row.notifiedTs) {
            currentBatch.current = row.batchNum;
          }
          // If all are declined, manual notified or both (the entire batch has only manual notified/decliend) then we must skip the batch
          if ((allAreDeclined || allAreManualNotified || allAreDeclinedOrManualNotified) && bidsInCurrentBatch.length > 1) {
            currentBatch.current = 0;
          }
          // Adding the actual timer between the correct rows
          if (row.isLastFromBatch && !!row.batchNum && row.batchNum !== lastBatch && row.batchNum === currentBatch.current && !allAreManualNotified && !allAreDeclined && !allAreDeclinedOrManualNotified) {
            sortedRows.splice(idx + 1, 0, {
              id: "timer",
              timer: timeUntilNextNotification * 1000,
              isLast: false
            })
          }
          // Adding the final timer at the end of all batches
          if (row.isLastFromBatch && !!row.batchNum && row.batchNum === lastBatch && row.batchNum === currentBatch.current) {
            sortedRows.splice(idx + 1, 0, {
              id: "timer",
              timer: timeUntilNextNotification * 1000,
              isLast: true
            })
          }
        }
      })

      // If some notary accepted the bid or was awarded, the bidding process it's paused so we need to have this
      // information because we need to clear all the timers
      const someNotaryAcceptedOrAwarded = sortedRows.some(row => row.status === NotaryBid.STATUS_ACCEPTED_BY_NOTARY || row.status === NotaryBid.STATUS_AWARDED_BY_SCHEDULER)
      // Setting the final rows (which the user will see)
      let finalRows = [...sortedRows];
      // Clear the timers
      if (someNotaryAcceptedOrAwarded) {
        currentBatch.current = 0;
        finalRows = sortedRows.filter(row => !row.timer);
      }
      // Adding row counting, we need to exclude the timers (timers are also rows)
      let rowCount = 0;
      finalRows.forEach((row) => {
        if (!row.timer) {
          rowCount += 1;
          row.rowCount = rowCount
        }
      })
      // If the bidding is exhausted we need to set the current batch to 0
      if (order.isNotarySearchExhausted) {
        currentBatch.current = 0;
      }
      // Returning the final rows seen by the user
      return finalRows;
    } else {
      return rows;
    }
  }

  return <React.Fragment>
    <Card className="paginated-table-card">
      <CardBody className="pt-3">
        <ToolkitProvider
          keyField="id"
          columns={columns(confirmAwardBid, confirmDeclineBid, confirmNotifyBid, requeueBid, currentBatch, order)}
          data={formatRows(rows)}
        >
          {toolkitProps => (
            <React.Fragment>
              <Row>
                <Col>
                  <div className="table-responsive">
                    <BootstrapTable
                      {...getSharedTableOptions()}
                      headerWrapperClasses="thead-light hide-expand-all"
                      noDataIndication={!rowsError && isNotarySearchExhausted}
                      expandRow={expandRow}
                      {...toolkitProps.baseProps}
                      rowClasses={rowClasses}
                    />
                  </div>
                  {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                  {!!rowsError && <Alert color="danger" className="fade show text-center">
                    <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load notary bids
                  </Alert>}
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
    {isAwardConfirmationVisible && <Confirmation
      confirmBtnText="Assign"
      success
      confirmBtnBsStyle="success"
      style={{ backgroundColor: '#ffffff' }}
      reverseButtons={false}
      onConfirm={() => awardBid()}
      onCancel={() => {
        setBidToAward(null);
        setIsAwardConfirmationVisible(false);
      }}>
      Are you sure you want to assign notary &quot;{bidToAward.notaryUserFullName}&quot; to order #{order.id}?
    </Confirmation>}
    {isDeclineConfirmationVisible && <Confirmation
      confirmBtnText="Decline"
      reverseButtons={false}
      onConfirm={() => declineBid()}
      onCancel={() => {
        setBidToDecline(null);
        setIsDeclineConfirmationVisible(false);
      }}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to decline notary &quot;{bidToDecline.notaryUserFullName}&quot; to order #{order.id}?</span>
    </Confirmation>}
    {isNotifyConfirmationVisible && <Confirmation
      confirmBtnText="Notify"
      success
      confirmBtnBsStyle="success"
      style={{ backgroundColor: '#ffffff' }}
      reverseButtons={false}
      onConfirm={() => notifyBid()}
      onCancel={() => {
        setBidToNotify(null);
        setIsNotifyConfirmationVisible(false);
      }}>
      Are you sure you want to notify notary &quot;{bidToNotify.notaryUserFullName}&quot; to order #{order.id}?
    </Confirmation>}
  </React.Fragment>
}

const handleSwitch = (row, confirmAwardFC, confirmDeclineFC, confirmNotifyFC, valForTimeUntilExpire, requeueBidFC, order) => {
  switch (row.status) {
    case NotaryBid.STATUS_SELECTED:
      return <div className="d-flex align-items-center justify-content-between">
        <Button type="button" color="link" className="me-3 button-status-p" onClick={() => confirmNotifyFC(row)}>
          <i id='scheduled-icon' className="mdi mdi-email-send text-success font-size-19" />
          <UncontrolledTooltip placement="top" target="scheduled-icon">Notify the notary</UncontrolledTooltip>
        </Button>
        <Button type="button" color="link" className="button-status-p btn btn-link" onClick={() => confirmDeclineFC(row)}>
          <i id='reject-icon' className="mdi mdi-delete delete-btn text-danger font-size-19" />
          <UncontrolledTooltip placement="top" target="reject-icon">Reject the notary</UncontrolledTooltip>
        </Button>
      </div>
    case NotaryBid.STATUS_NOTIFIED:
      return (
        <>
          <div className="d-flex align-items-center justify-content-between">
            {
              order.isNotarySearchExhausted
                ?
                <>
                  {
                    // notifiedTs will be null after the bidding has closed (some kind of refresh)
                    !row.notifiedTs
                      ?
                      <div className='button-status-p'>
                        <div className="expired-notice" onClick={() => requeueBidFC(row)}>
                          <i id="requeue-icon" className="bx bx-revision text-secondary font-size-20"></i>
                          <UncontrolledTooltip placement="top" target="requeue-icon">Add the notary back into the bidding process.</UncontrolledTooltip>
                        </div>
                      </div>
                      :
                      <>
                        <img id='pending-icon' className="me-3 button-status-p" alt="notary-pending" src={notaryPendingIcon} />
                        <UncontrolledTooltip placement="top" target="pending-icon">Waiting for notary action</UncontrolledTooltip>
                      </>
                  }
                </>
                :
                <>
                  <img id='pending-icon' className="me-3 button-status-p" alt="notary-pending" src={notaryPendingIcon} />
                  <UncontrolledTooltip placement="top" target="pending-icon">Waiting for notary action</UncontrolledTooltip>
                </>
            }
            <Button type="button" color="link" className="button-status-p" onClick={() => confirmDeclineFC(row)}>
              <i id='reject-icon' className="mdi mdi-delete delete-btn text-danger font-size-19" />
              <UncontrolledTooltip placement="top" target="reject-icon">Reject the notary</UncontrolledTooltip>
            </Button>
          </div>
        </>
      )
    case NotaryBid.STATUS_DECLINED_BY_NOTARY:
      return <div className="d-flex align-items-center justify-content-between">
        <div className="m-0 me-3 button-status-p">
          <img id='declined-icon' alt="assign-notary" src={rejectedByNotaryIcon} />
          <UncontrolledTooltip placement="top" target="declined-icon">Rejected by notary</UncontrolledTooltip>
        </div>
        <div className='button-status-p'>
          <div className="expired-notice" onClick={() => requeueBidFC(row)}>
            <i id="requeue-icon" className="bx bx-revision text-secondary font-size-20"></i>
            <UncontrolledTooltip placement="top" target="requeue-icon">Add the notary back into the bidding process.</UncontrolledTooltip>
          </div>
        </div>
      </div>
    case NotaryBid.STATUS_ACCEPTED_BY_NOTARY:
      return <div className="d-flex align-items-center justify-content-between">
        <Button type="button" color="link" className="m-0 button-status-p" onClick={() => confirmAwardFC(row)}>
          <img id='accepted-icon' alt="assign-notary" src={assignNotaryIcon} />
          <UncontrolledTooltip placement="top" target="accepted-icon">Accepted by notary. Click to award.</UncontrolledTooltip>
        </Button>
        <Button type="button" color="link" className="button-status-p btn btn-link" onClick={() => confirmDeclineFC(row)}>
          <i id='reject-icon' className="mdi mdi-delete delete-btn text-danger font-size-19" />
          <UncontrolledTooltip placement="top" target="reject-icon">Reject the notary</UncontrolledTooltip>
        </Button>
      </div>
    case NotaryBid.STATUS_REJECTED_BY_SCHEDULER:
      return <div className="d-flex align-items-center justify-content-between">
        <div className="m-0 me-3 button-status-p">
          <i id='rejected-scheduler-icon' className="mdi mdi-minus-circle text-danger font-size-19" />
          <UncontrolledTooltip placement="top" target="rejected-scheduler-icon">Rejected by scheduler</UncontrolledTooltip>
        </div>
        <div className='button-status-p'>
          <div className="expired-notice" onClick={() => requeueBidFC(row)}>
            <i id="requeue-icon" className="bx bx-revision text-secondary font-size-20"></i>
            <UncontrolledTooltip placement="top" target="requeue-icon">Add the notary back into the bidding process.</UncontrolledTooltip>
          </div>
        </div>
      </div>
    case NotaryBid.STATUS_AWARDED_BY_SCHEDULER:
      return <div className="d-flex align-items-center            ">
        <div className="m-0 me-3 button-status-p">
          <img id='award-icon' alt="assign-notary" src={notaryAwardedIcon} />
          <UncontrolledTooltip placement="top" target="award-icon">Awarded by scheduler</UncontrolledTooltip>
        </div>
      </div>
  }
}

const getBidActionButtons = (row, confirmAwardFC, confirmDeclineFC, confirmNotifyFC, valForTimeUntilExpire, requeueBidFC, order) => {
  const canRequeue = (row.status == NotaryBid.STATUS_NOTIFIED && row.isExpired) || [NotaryBid.STATUS_NOT_AWARDED, NotaryBid.STATUS_CLOSED_BY_AWARD].includes(row.status);
  if (canRequeue) {
    return <div className="button-status-p">
      <RequeueButton targetDate={valForTimeUntilExpire} handleRequeue={() => requeueBidFC(row)} />
    </div>
  }
  return handleSwitch(row, confirmAwardFC, confirmDeclineFC, confirmNotifyFC, valForTimeUntilExpire, requeueBidFC, order);
}

const columns = (confirmAwardBid, confirmDeclineBid, confirmNotifyBid, requeueBid, currentBatch, order) => [{
  // this column is not used but we need it for the default sorting to work
  // if dataField does not match a defined column, it will be ignored
  dataField: "id",
  text: "#",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cell, row) => !!row.timer ?
    <div className="position-relative">
      <div className="next-notif-timer until-next-notification">
        {!row.isLast && "Next Notification In"}
        <CountdownTimer targetDate={row.timer} stateChanger={() => { currentBatch.current = 0 }} />
      </div>
    </div>
    :
    row.rowCount,
}, {
  dataField: "notaryType",
  text: "Type",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent) => (
    cellContent === Notary.TYPE_SUPER_EXPRESS
      ?
      <div>
        <img className="me-1" src={senIcon} />
        {Notary.getTypeName(cellContent)}
      </div>
      :
      Notary.getTypeName(cellContent)
  )
}, {
  dataField: "notaryUserFullName",
  text: "Name",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <Link to={route(routes.view_notary, row.notaryId)}>{cellContent}</Link>
}, {
  dataField: "initialRating",
  text: "Mav1 Rating",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => (cellContent ? <>{cellContent} <i className="mdi mdi-star-outline mb-2 rating-star text-warning" /></> : !row.timer ? "--" : "")
}, {
  dataField: "notaryRating",
  text: "Rating",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => (cellContent ? <>{cellContent} <i className="mdi mdi-star-outline mb-2 rating-star text-warning" /></> : !row.timer ? "--" : "")
}, {
  dataField: "countiesCovered",
  text: "Coverage Area",
  sort: false,
  classes: "additional-details-cell",
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    if (!!cellContent) {
      return cellContent;
    } else if (!row.timer) {
      return "--";
    } else {
      return "";
    }
  }
}, {
  dataField: "state",
  text: "State",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    if (!!cellContent) {
      return cellContent;
    } else if (!row.timer) {
      return "--";
    } else {
      return "";
    }
  }
}, {
  dataField: "distance",
  text: "Distance",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => (cellContent ? `${getMiles(cellContent)} miles` : !row.timer ? "--" : "")
}, {
  dataField: "scheduleAlert",
  text: "Additional Details",
  sort: false,
  classes: "additional-details-cell",
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    if (!!cellContent) {
      return cellContent;
    } else if (!row.timer) {
      return "--";
    } else {
      return "";
    }
  }
}, {
  dataField: "comments",
  text: "Notary Comments",
  classes: "additional-details-cell",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    if (!!cellContent) {
      return cellContent;
    } else if (!row.timer) {
      return "--";
    } else {
      return "";
    }
  }
}, {
  dataField: "dummy2",
  text: "Availability",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    if (!row.timer) {
      if (!row.availability) {
        return '--';
      }
      let availabilityName = [];
      for (const availability of row.availability) {
        availabilityName.push(NotaryBid.getAvailabilityName(availability));
      }
      return availabilityName.join(', ')
    } else {
      return "";
    }
  }
}, {
  dataField: "dummy1",
  text: "Actions",
  classes: "actions-cell",
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row, notifiedTs) => {
    const notaryBidExpiry = +config.NOTARY_BID_EXPIRY;
    const timeUntilExpire = (notifiedTs + notaryBidExpiry) * 1000;

    if (!row.timer) {
      return getBidActionButtons(row, confirmAwardBid, confirmDeclineBid, confirmNotifyBid, timeUntilExpire, requeueBid, order);
    } else {
      return "";
    }
  }
},];

const expandRow = {
  showExpandColumn: true,
  expandColumnPosition: "right",
  expandByColumnOnly: true,
  parentClassName: "expanded-dt-row",
  // eslint-disable-next-line react/display-name, react/prop-types
  expandColumnRenderer: ({ expanded, rowKey }) => {
    if (rowKey !== "timer") {
      return (
        <button
          className={classnames(
            "accordion-button",
            "w-auto",
            "fw-medium",
            { collapsed: !expanded }
          )}
          type="button"
        />
      )
    } else {
      return null;
    }
  },
  // eslint-disable-next-line react/display-name
  renderer: (row) => (
    <div className="d-flex justify-content-around p-3">
      <div>
        <span className="me-2">
          <strong>Rating:</strong>
        </span>
        {row.notaryRating ? <span>
          {row.notaryRating} <i className="mdi mdi-star-outline text-warning font-size-16 ms-1" />
        </span> : "--"}
      </div>
      <div className="vertical-divider" />
      <div>
        <span className="me-2">
          <strong>Phone No:</strong>
        </span>
        {row.phone}
      </div>
      <div className="vertical-divider" />
      <div>
        <span className="me-2">
          <strong>Email:</strong>
        </span>
        {row.notaryEmail}
      </div>
    </div>
  ),
  // the timer rows should not be expandable
  nonExpandable: ["timer"],
};

export default DataTableNotaryBids;
