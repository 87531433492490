import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Button, Card, CardBody, CardHeader, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import Col from "components/Shared/Col";
import { dictionaryToSelectOptions, nullsToEmptyStrings, showBriefError, showError, showSuccess } from "helpers/utilHelper";
import { useFormik } from "formik";
import { updateOrderTrackingAndShipping } from "helpers/backendHelper";
import { ValidationException } from "helpers/errorHelper";
import Order from 'model/order';
import { UNABLE_SEND_TRACKING_INFO_ADDED_NOTIF } from 'helpers/errorHelper';

const FormEditTrackingAndShipping = ({ defaultValues, finishedHandler, id }) => {
  /********** STATE **********/
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/
  const formInitialValues = {
    shippingCompany: '',
    shippingPackageAwb: '',
    returnPackageAwb: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    onSubmit: values => saveTrackingAndShipping(values, id),
  })

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  useEffect(() => {
    getShippingCompanyOptions()
  }, []);

  /********** EVENT HANDLERS **********/

    // focus event handler
    // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
      const name = fieldName || e.target.name;
      const errors = formik.errors;
      delete errors[name];
      formik.setStatus(errors);
    }
  /********** OTHER **********/

  const saveTrackingAndShipping = (values, id) => {
    setIsSaveInProgress(true);
    updateOrderTrackingAndShipping(values, id)
      .then(response => {
        showSuccess('Tracking and Shipping has been saved');
        finishedHandler(true);
      })
      .catch(error => {
        if(error.code === UNABLE_SEND_TRACKING_INFO_ADDED_NOTIF) {
          showError("Unable to send Tracking and Shipping notification");
        } else showError('Unable to save Tracking and Shipping');

        // see if the save failed due to validation
        if (error instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(error.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  const getShippingCompanyOptions = () => {
    const options = dictionaryToSelectOptions(Order.getShippingCompanyMap());
    const otherCompanyOption = options.find(option => option.value === Order.SHIPPING_COMPANY_OTHER);
    // reorder the options by keeping `Other` as the last element
    return [...options.filter(option => option.value !== Order.SHIPPING_COMPANY_OTHER), otherCompanyOption];
  };

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Tracking and Shipping</div>
          </Col>
          <Col xs="auto" className="text-end">
            <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2" onClick={() => finishedHandler()}>
              <i className="mdi mdi-chevron-left me-1" />Cancel
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="p-0">
        <Form className="pt-4">
          <div className="card-section">
            <Row className="mb-4">
              <Col xl="6">
                <Label>Shipping Company:</Label>
                <Select
                  classNamePrefix="select2-selection"
                  name="shippingCompany"
                  options={getShippingCompanyOptions()}
                  onChange={selected => formik.setFieldValue("shippingCompany", selected.value)}
                  onFocus={e => onFieldFocused(e, "shippingCompany")}
                  value={getShippingCompanyOptions().find(option => option.value === formik.values.shippingCompany)}
                  className={!!formik.errors.shippingCompany && "is-invalid"}
                />
                {!!formik.errors.shippingCompany && <FormFeedback type="invalid">{formik.errors.shippingCompany}</FormFeedback>}
              </Col>
              <Col xl="6">
                <Label>Shipping Package</Label>
                <Input type="text" className="form-control" name="shippingPackageAwb" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.shippingPackageAwb} invalid={!!formik.errors.shippingPackageAwb} />
                {!!formik.errors.shippingPackageAwb && <FormFeedback type="invalid">{formik.errors.shippingPackageAwb}</FormFeedback>}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xl="6">
                <Label>Return Package</Label>
                <Input type="text" className="form-control" name="returnPackageAwb" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.returnPackageAwb} invalid={!!formik.errors.returnPackageAwb} />
                {!!formik.errors.returnPackageAwb && <FormFeedback type="invalid">{formik.errors.returnPackageAwb}</FormFeedback>}
              </Col>
            </Row>
          </div>
        </Form>
      </CardBody>
    </Card>
  </React.Fragment>
}

FormEditTrackingAndShipping.propTypes = {
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  finishedHandler: PropTypes.func,
}

export default FormEditTrackingAndShipping;