import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col, CardHeader, CardTitle, Table } from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import { decimalToDMS } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";

const SectionContact = (props) => {

  const { notary, isLoadInProgress } = props;

  const latitudeVal = decimalToDMS(notary.shippingLatitude, notary.shippingLatitude);
  const longitudeVal = decimalToDMS(notary.shippingLongitude);

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Contact Information</CardTitle>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Table className="section-rows table bt-1">
        <tbody>
          <tr>
            <td className="section-row-label">Contact Person</td>
            <td className="section-row-value ps-4">
              <Link to={route(routes.view_user, notary.userId)}>{notary.userFullName}</Link>
            </td>
          </tr>
          <tr>
            <td className="section-row-label">Phone</td>
            <td className="section-row-value ps-4">{notary.phone || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Email Address</td>
            <td className="section-row-value ps-4">{notary.email || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Website</td>
            <td className="section-row-value ps-4">{notary.website || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Google map</td>
            <td className="section-row-value ps-4">
              <a className='text-decoration-underline' href={`https://www.google.com/maps/place/${latitudeVal},${longitudeVal}`} title={`${notary.shippingLatitude},${notary.shippingLongitude}`}>
                {latitudeVal} {longitudeVal}
              </a>
            </td>
          </tr>
          <tr>
            <td className="section-row-label no-border">T&C and NDA agreement</td>
            <td className="section-row-value ps-4 no-border">{!!notary.termsAcceptedTs ? formatTimestamp(notary.termsAcceptedTs, formats.DATE_PT_FORMAT) : "---"}</td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
    {isLoadInProgress && <CardPreloader />}
  </Card>)
}

SectionContact.propTypes = {
  notary: PropTypes.object,
  isLoadInProgress: PropTypes.bool
};

export default SectionContact;