import React, { useEffect, useRef } from "react"
import { useLocation, useNavigate } from 'react-router-dom';

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link } from "react-router-dom"

import { route, routes } from "helpers/routeHelper"
import { perms, useAccess } from "context/access"

const SidebarContent = () => {

  // const currentRoute = useSelector(state => state.currentRoute);
  const navigate = useNavigate();

  const location = useLocation();

  const ref = useRef();
  // hooks that check permissions
  const { iAmGranted, iAmGrantedAny } = useAccess();

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        let activeFor = items[i].getAttribute('data-active-for');
        let isActiveFor = false;
        if (activeFor) {
          activeFor = activeFor.split('~');
          isActiveFor = activeFor.some(function (pattern) {
            var re = new RegExp(pattern);
            return re.test(pathName);
          });
        }
        if (pathName === items[i].pathname || isActiveFor) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [location.href])

  useEffect(() => {
    ref.current.recalculate()
  })


  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    if (item.classList.contains('active')) {
      return;
    }
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className="h-100">
          <ul className="metismenu list-unstyled d-flex flex-column h-100" id="side-menu">
            <li className="menu-title">Menu</li>

            <li>
              <Link to={route(routes.home)} data-active-for="^\/dashboard($|\/(.*)+)">
                <i className="bx bx-home-circle"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            {iAmGranted(perms.view_users) && <li>
              <Link to={route(routes.list_users)} data-active-for="^\/user($|\/(.*)+)">
                <i className="bx bxs-user"></i>
                <span>Users</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_support_cases) && <li>
              <Link to={route(routes.list_support_cases)} data-active-for="^\/support-case($|\/(.*)+)" className="support-cases-menu">
                <i className="mdi mdi-headset support-cases-menu"></i>
                <span className="support-cases-menu">Support cases</span>
              </Link>
            </li>}

            {iAmGrantedAny([perms.view_dealer_groups, perms.view_dealer_stores]) && <li>
              <Link to="/#" className="has-arrow ">
                <i className="mdi mdi-store"></i>
                <span>Dealers</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {iAmGranted(perms.view_dealer_groups) && <li>
                  <Link to={route(routes.list_dealer_groups)} data-active-for="^\/dealer-group($|\/(.*)+)~^\/dealer-region($|\/(.*)+)">Groups</Link>
                </li>}
                {iAmGranted(perms.view_dealer_stores) && <li>
                  <Link to={route(routes.list_dealer_stores)} data-active-for="^\/dealer-store($|\/(.*)+)">Stores</Link>
                </li>}
              </ul>
            </li>}

            {iAmGranted(perms.view_notaries) && <li>
              <Link to={route(routes.list_notaries)} data-active-for="^\/(notaries|notary)($|\/(.*)+)">
                <i className="mdi mdi-fountain-pen-tip"></i>
                <span>Notaries</span>
              </Link>
            </li>}


            {iAmGranted(perms.view_biddings) && <li>
              <Link to={route(routes.view_biddings)} data-active-for="^\/bidding($|\/(.*)+)">
                <i className="mdi mdi-scale-balance"></i>
                <span>Biddings</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_orders) && <li>
              <Link to="/#" className="has-arrow">
                <i className="mdi mdi-shopping"></i>
                <span>Orders</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to={route(routes.list_orders_remote)} data-active-for="^\/orders-remote($|\/(.*)+)">Remote Orders</Link>
                </li>
                <li>
                  <Link to={route(routes.list_orders_in_store)} data-active-for="^\/in-store-orders($|\/(.*)+)">In Store Orders</Link>
                </li>
              </ul>
            </li>}

            {iAmGranted(perms.view_scheduler_performance_reports) &&
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-chart-line"></i>
                  <span>Reports</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {iAmGranted(perms.view_scheduler_performance_reports) && <li>
                    <Link to={route(routes.view_scheduler_performance_report)} data-active-for="^\/scheduler-performance-report($|\/(.*)+)">Performance report</Link>
                  </li>}
                  {iAmGranted(perms.view_group_usage_reports) && <li>
                    <Link to={route(routes.view_group_usage_report)} data-active-for="^\/group-usage-report($|\/(.*)+)">Group usage report</Link>
                  </li>}
                  {iAmGranted(perms.view_dealer_sales_reports) && <li>
                    <Link to={route(routes.view_dealer_sales_report)} data-active-for="^\/dealer-sales-report($|\/(.*)+)">Dealer sales report</Link>
                  </li>}
                  {iAmGranted(perms.view_new_dealers_reports) && <li>
                    <Link to={route(routes.view_new_dealers_report)} data-active-for="^\/new-dealers-report($|\/(.*)+)">New dealers report</Link>
                  </li>}
                </ul>
              </li>}

            {iAmGranted(perms.view_pending_review_orders) && <li>
              <Link to={route(routes.list_pending_review_orders)} data-active-for="^\/pending-review($|\/(.*)+)" className="immediate-review-menu">
                <i className="fas fa-fast-forward immediate-review-menu"></i>
                <span className="immediate-review-menu">Pending Review Orders</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_payment_plans) && <li>
              <Link to={route(routes.list_payment_plans)} data-active-for="^\/payment-plan($|\/(.*)+)">
                <i className="mdi mdi-wallet-membership"></i>
                <span>Payment plans</span>
              </Link>
            </li>}

            {iAmGrantedAny([perms.view_statements, perms.view_payments, perms.view_quickbooks]) && <li><Link to="/#" className="has-arrow">
              <i className="mdi mdi-script-outline" />
              <span>Accounting</span>
            </Link>
              <ul className="sub-menu" aria-expanded="false">
                {iAmGranted(perms.view_statements) && <li>
                  <Link to="/#" className="has-arrow">
                    <span>Statements</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to={route(routes.list_dealer_statements)} data-active-for="^\/dealer-statement($|\/(.*)+)">Dealers</Link>
                    </li>
                    <li>
                      <Link to={route(routes.list_notary_statements)} data-active-for="^\/notary-statement($|\/(.*)+)">Notaries</Link>
                    </li>
                  </ul>
                </li>}
                {iAmGranted(perms.view_payments) && <li>
                  <Link to={route(routes.list_payments)} data-active-for="^\/payment($|\/(.*)+)">Payments</Link>
                </li>}
                {iAmGranted(perms.view_quickbooks) && <>
                  <li>
                    <Link to={route(routes.view_quickbooks)} data-active-for="^\/quickbooks($|\/(.*)+)~^\/dealer-region($|\/(.*)+)">Quickbooks setup</Link>
                  </li>
                </>}
              </ul>
            </li>}

            {iAmGranted(perms.view_messages) && <li>
              <Link to={route(routes.list_messages)} data-active-for="^\/message($|\/(.*)+)">
                <i className="mdi mdi-message-text"></i>
                <span>Messages</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_additional_fees) && <li>
              <Link to={route(routes.list_additional_fees)} data-active-for="^\/additional-fee($|\/(.*)+)">
                <i className="mdi mdi-currency-usd-circle"></i>
                <span>Additional Fees</span>
              </Link>
            </li>}

            {iAmGrantedAny([perms.view_cron_jobs, perms.view_error_codes]) && <li>
              <Link to="/#" className="has-arrow ">
                <i className="mdi mdi-cog"></i>
                <span>System</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {iAmGranted(perms.view_cron_jobs) && <li>
                  <Link to={route(routes.list_cron_jobs)} data-active-for="^\/cron-job($|\/(.*)+)">Cron Jobs</Link>
                </li>}
                {iAmGranted(perms.view_error_codes) && <li>
                  <Link to={route(routes.list_error_codes)}>Error Codes</Link>
                </li>}
                {iAmGranted(perms.view_system_processes) && <li>
                  <Link to={route(routes.list_sys_processes)}>Processes</Link>
                </li>}
                {iAmGranted(perms.view_route_one_logs) && <li>
                  <Link to={route(routes.list_route_one_logs)}>Route One Logs</Link>
                </li>}
              </ul>
            </li>}

            <li>
              <Link to={route(routes.list_help)} data-active-for="^\/help($|\/(.*)+)">
                <i className="mdi mdi-help-circle-outline"></i>
                <span>Help section</span>
              </Link>
            </li>

            <li>
              <Link to={route(routes.route_one)} data-active-for="^\/route-one($|\/(.*)+)">
                <i className="mdi mdi-flag-checkered"></i>
                <span className="badge rounded-pill bg-primary float-end">New</span>
                <span>RouteOne</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default SidebarContent;
