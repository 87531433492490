import React from 'react';
import PropTypes from 'prop-types';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';
import OrderDoc from 'model/orderDoc';

const OrderDocSignedRebuilt = props => {

  const { log } = props;

  return <React.Fragment>
    <div className="d-flex">
      <AuthorAvatar log={log} />
      <div className="flex-grow-1">
        <AuthorName log={log} /> OrderDocSignedRebuilt.
        <p className="mb-0"><EventTime log={log} /></p>
      </div>
    </div>
  </React.Fragment>
}

OrderDocSignedRebuilt.propTypes = {
  log: PropTypes.object,
}

export default OrderDocSignedRebuilt;