import React from 'react';
import PropTypes from 'prop-types';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';

const OrderFeeUpdated = props => {

  const { log } = props;

  const appliesToLabel = !!log.subject.dealerStoreId ? 'dealer' : 'notary';

  return <div className="d-flex">
    <AuthorAvatar log={log} />
    <div className="flex-grow-1">
      <AuthorName log={log} /> updated {appliesToLabel} fee <b>{log.subject.additionalFeeName}</b> to <b>{log.subject.price !== null ? `$${log.subject.price}` : 'not set'}</b>{log.newValues.count > 1 && ` x${log.newValues.count}`}.
      <p className="mb-0"><EventTime log={log} /></p>
    </div>
  </div>
}

OrderFeeUpdated.propTypes = {
  log: PropTypes.object,
}

export default OrderFeeUpdated;